import { SVGProps } from 'react'

const Power = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M7.95094 2.00099H14.587C14.6669 2.00071 14.7457 2.02045 14.8162 2.05843C14.8866 2.09641 14.9465 2.15142 14.9904 2.21848C15.0344 2.28555 15.0609 2.36254 15.0677 2.4425C15.0745 2.52246 15.0613 2.60285 15.0293 2.6764L12.433 8.75693H16.8096C16.8463 8.75731 16.8821 8.76818 16.9128 8.78827C16.9435 8.80837 16.9678 8.83684 16.9829 8.87035C16.998 8.90385 17.0033 8.94099 16.998 8.97739C16.9928 9.01378 16.9773 9.04793 16.9534 9.0758L8.02787 18.9184C8.00351 18.9549 7.96691 18.9815 7.9247 18.9933C7.88249 19.0051 7.83748 19.0013 7.79782 18.9826C7.75816 18.9639 7.72649 18.9316 7.70854 18.8915C7.69059 18.8514 7.68757 18.8062 7.70001 18.764L8.5856 11.6515H4.48824C4.40866 11.6526 4.33006 11.6339 4.25945 11.5971C4.18885 11.5602 4.12844 11.5064 4.08363 11.4404C4.03882 11.3744 4.011 11.2983 4.00265 11.2189C3.99431 11.1395 4.00571 11.0592 4.03582 10.9853L7.49761 2.29964C7.53431 2.2097 7.59723 2.13298 7.67812 2.07953C7.75901 2.02607 7.8541 1.9993 7.95094 2.00099Z"
      stroke="#878787"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default Power
