import React from 'react'
import { useQuery } from 'react-query'
import { indexBy, prop, path, pathOr } from 'ramda'
import request, {
  convertData,
  Mutation,
  MutationOptions,
  useMakeMutation,
  QueryVariables,
} from '@/services'
import useComapnyCode from '@/hooks/useComapnyCode'
import { useCoefficientTypeList } from '@/options'

import type { FirmCoeffi, FirmCoeffiDetail, CoffiFuel } from './firm.type'

export const useFirmCoeffiList = ({ variables = {}, ...options }: any = {}) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })
  const { byId } = useCoefficientTypeList()

  const { data, refetch, ...rest } = useQuery<FirmCoeffi>(
    [
      '/{companyCode}/coefficient/customizeCoefficient/list',
      {
        companyCode,
        ...variables,
      },
    ],
    {
      enabled: !!companyCode,
      ...options,
    }
  )

  const dataSource = React.useMemo(
    () =>
      convertData<FirmCoeffi>(
        {
          field: ['data'],
          converter: x => ({
            ...x,
            coefficientTypeName: byId && byId[Number(x.coefficientType)]?.name,
          }),
        },
        data
      ),
    [data, byId]
  )

  return {
    ...rest,
    refetch,
    dataSource,
  }
}

export const useFirmCoeffiDetail = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, refetch, ...others } = useQuery(
    [
      '/{companyCode}/coefficient/customizeCoefficient/{id}',
      { companyCode, ...variables },
    ],
    {
      enabled: !!variables?.id,
      select: res => path<FirmCoeffiDetail>(['data'], res),
      cacheTime: 0,
      ...options,
    }
  )
  return {
    ...others,
    refetch,
    data: data as FirmCoeffiDetail,
  }
}

export const useCreateFirmCoeffi = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: any) =>
      request(`/{companyCode}/coefficient/customizeCoefficient`, {
        method: 'POST',
        body: {
          companyCode,
          ...body,
        },
      }),
    options
  )
}

export const useUpdateFirmCoeffiDetail = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: any) =>
      request(`/{companyCode}/coefficient/customizeCoefficient/{id}`, {
        method: 'PUT',
        body: { companyCode, ...body },
      }),
    options
  )
}

export const useCoffiFuelList = ({ variables = {}, ...options }: any = {}) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, refetch, ...rest } = useQuery<CoffiFuel[]>(
    ['/{companyCode}/coefficient/fuels', { companyCode, ...variables }],
    {
      enabled: !!companyCode,
      select: data => {
        return pathOr([], ['data'], data)
      },
      ...options,
    }
  )

  const byId = React.useMemo(() => {
    return indexBy(prop('id'), data || [])
  }, [data])

  return {
    ...rest,
    refetch,
    data,
    byId,
  }
}

export const useChangeFirmCoeffiStatus = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: { status: -1 | 0 | 1 }) =>
      request(`/{companyCode}/coefficient/customizeCoefficient/status/{id}`, {
        method: 'PUT',
        body: { companyCode, ...body },
      }),
    options
  )
}
