import envStore from '@lib/env'
import Store from 'store'

const set = (name: string, value: any) =>
  Store.set(`${envStore.localStoragePrefix}_${name}`, value)
const get = (name: string) =>
  Store.get(`${envStore.localStoragePrefix}_${name}`)

const remove = (name: string) => {
  Store.remove(`${envStore.localStoragePrefix}_${name}`)
}

const clearAll = () => Store.clearAll()

const localStorageUtil = {
  set,
  get,
  clearAll,
  remove,
}

export default localStorageUtil
