import { useMutation, useQuery, UseQueryOptions } from 'react-query'
import { compose, forEach, pathOr, prop, values } from 'ramda'
import request, { convertData } from '@/services'
import { Func, FuncDefList, UserListType, UserManagementRole } from './types'
import { setFunc } from './converter'
import useComapnyCode from '@/hooks/useComapnyCode'
import i18n from 'i18next'

export const useRoles = (querys?: { [key: string]: any }) => {
  const { data, isLoading, refetch } = useQuery([
    '/memberRole/list/{pageNum}/{pageSize}',
    querys,
  ])

  const dataSource = convertData<UserManagementRole>({ field: ['data'] }, data)

  return { dataSource, isLoading, refetch }
}

export const useUserList = (querys: { [key: string]: any }) => {
  const { data, isLoading, refetch } = useQuery([
    '/account/sys/list/{pageNum}/{pageSize}',
    querys,
  ])

  const dataSource = convertData<UserListType>({ field: ['data'] }, data)

  return { dataSource, isLoading, refetch }
}

export const useCompanyUserList = (querys: { [key: string]: any }) => {
  const companyCode = useComapnyCode()

  const { data, isLoading, refetch } = useQuery([
    '/{companyCode}/company/account/list/{pageNum}/{pageSize}',
    { companyCode, ...querys },
  ])

  const dataSource = convertData<UserListType>({ field: ['data'] }, data)

  return { dataSource, isLoading, refetch }
}

export const useUserById = (
  querys: { id?: number },
  options?: UseQueryOptions<UserListType>
) => {
  return useQuery<UserListType>(['/account/detail/{id}', querys], {
    enabled: !!querys?.id,
    select: (res: any) => prop('data', res),
    ...options,
  })
}

export type ChangePasswordBody = {
  id: number
  newPassword: string
}

export const useChangePasswordApi = () =>
  useMutation((body: ChangePasswordBody) => {
    return request('/account/password/{id}', {
      body,
      method: 'POST',
      successText: i18n.t('common:update password success'),
    })
  })

export type ModifySysUserBody = {
  memberId: string
  memberRoleSeq: number
  nickname: string
  password: string
  sessionIds?: number[]
  status: number
  allSessionPermission?: boolean
  allCameraPermission?: boolean
  deviceGroupSeqs?: number[]
  alldDeviceGroup: boolean
}

export const useAddSysUser = (successText?: string) =>
  useMutation((body: ModifySysUserBody) => {
    return request('/account/sys', {
      body,
      method: 'POST',
      successText: successText || i18n.t('common:new account success'),
    })
  })

export const useAddCompanyUser = (successText?: string) => {
  const companyCode = useComapnyCode()
  return useMutation((body: ModifySysUserBody) => {
    return request('/{companyCode}/company/account', {
      body: { companyCode, ...body },
      method: 'POST',
      successText: successText || i18n.t('common:new account success'),
    })
  })
}

export const useUpdateSysUser = (successText?: string) =>
  useMutation((body: ModifySysUserBody & { id: number }) => {
    return request('/account/sys/{id}', {
      body,
      method: 'PUT',
      successText: successText || i18n.t('common:update account success'),
    })
  })

export const useUpdateCompanyUser = (successText?: string) => {
  const companyCode = useComapnyCode()

  return useMutation((body: ModifySysUserBody & { id: number }) => {
    return request('/{companyCode}/company/account/{id}', {
      body: { companyCode, ...body },
      method: 'PUT',
      successText: successText || i18n.t('common:update account success'),
    })
  })
}

export const useDeleteSysUser = (successText?: string) =>
  useMutation((body: { id: number }) => {
    return request('/account/sys/{id}', {
      body,
      method: 'DELETE',
      successText: successText || i18n.t('common:delete account success'),
    })
  })

export const useDeleteCompanyUser = (successText?: string) => {
  const companyCode = useComapnyCode()

  return useMutation((body: { id: number }) => {
    return request('/{companyCode}/company/account/{id}', {
      body: { companyCode, ...body },
      method: 'DELETE',
      successText: successText || i18n.t('common:delete account success'),
    })
  })
}

export const useGetSysDefList = () => {
  const { data } = useQuery(['/authority/defList'])
  let funcById: { [key: string]: Func } = {}
  compose(
    forEach((x: FuncDefList) => {
      funcById[x.name] = setFunc(x, funcById[x.name])
    }),
    pathOr([], ['data'])
  )(data)
  return { funcList: values(funcById) }
}

export const useAddMemberRole = () =>
  useMutation((body: any) => {
    return request('/memberRole', {
      body,
      method: 'POST',
      successText: i18n.t('common:new role success'),
    })
  })

export const useEditMemberRole = () =>
  useMutation((body: any) => {
    return request('/memberRole/{id}', {
      body,
      method: 'PUT',
      successText: i18n.t('common:update role success'),
    })
  })

export const useDeleteMemberRole = () =>
  useMutation((body: any) => {
    return request('/memberRole/{id}', {
      body,
      method: 'DELETE',
      successText: i18n.t('common:delete role success'),
    })
  })

export const useChangeMineAccountPassword = () => {
  return useMutation((body: { newPassword: string }) => {
    return request('/account/minePassword', {
      body,
      method: 'POST',
      successText: i18n.t('common:update password success'),
    })
  })
}

export const useChangeCompanyMineAccountPassword = () => {
  const companyCode = useComapnyCode()
  return useMutation((body: { newPassword: string }) => {
    return request('/{companyCode}/company/account/minePassword', {
      body: { ...body, companyCode },
      method: 'POST',
      successText: i18n.t('common:update password success'),
    })
  })
}

export const useRenewAccountPassword = () => {
  return useMutation((body: { newPassword: string }) => {
    return request('/account/renewMinePassword', {
      body,
      method: 'POST',
      successText: i18n.t('common:update password success'),
    })
  })
}

export const useRenewCompanyAccountPassword = () => {
  const companyCode = useComapnyCode()
  return useMutation((body: { newPassword: string }) => {
    return request('/{companyCode}/company/account/renewMinePassword', {
      body: { ...body, companyCode },
      method: 'POST',
      successText: i18n.t('common:update password success'),
    })
  })
}

export const useChangeCompanyAccountPassword = () => {
  const companyCode = useComapnyCode()
  return useMutation((body: { newPassword: string; id: number }) => {
    return request('/{companyCode}/company/account/password/{id}', {
      body: { ...body, companyCode },
      method: 'POST',
      successText: i18n.t('common:update password success'),
    })
  })
}
