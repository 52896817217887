import React from 'react'
import {
  merge,
  includes,
  isNil,
  always,
  T,
  path,
  ifElse,
  invoker,
  identity,
  mergeDeepRight,
  allPass,
  and,
  not,
  equals,
  pathOr,
  __,
} from 'ramda'
import toQueryString from '@/utils/toQueryString'
import { message, error } from '@/utils/message'
import { envStore, endpoints } from '@/env'
import { MutationOptions } from './services.types'
import i18n from 'i18next'
import localStorageUtil from '@lib/utils/localstorage'
const { compose, cond } = require('ramda')

/**
 * 替換Url中的變數，例如/v1/api/user/{id} 轉換成 /v1/api/user/5
 * @param url API Url
 * @param body Query Parameters
 */
export function format(url: string, params: { [key: string]: any } = {}) {
  const regex = /(\{.+?\})/gi
  const _params = { ...params }

  const _url = url.replace(regex, v => {
    const replacable = v[0] === '{'

    if (!replacable) {
      return v
    }

    const propName = v.slice(1, -1)
    const replacedValue = _params[propName]

    _params[propName] = undefined

    return replacedValue
  })

  return { fullUrl: _url, params: _params }
}

interface RequestInit_
  extends Omit<MutationOptions, 'onError' | 'onSuccess' | 'refetchQueries'> {
  body?: any
  credentials?: RequestCredentials
  headers?: HeadersInit
  integrity?: string
  keepalive?: boolean
  method?: 'DELETE' | 'PUT' | 'PATCH' | 'GET' | 'POST'
  mode?: RequestMode
  referrer?: string
  window?: any
  displayError?: boolean
  throwable?: boolean
  withToken?: boolean
  endpoint?: 'apiBaseUrl' | 'fakeApiBaseUrl'
  signal?: AbortSignal | null | undefined
  onError?: (err: Error) => any
  onSuccess?: (data: any) => any
}

export function useMeta() {
  const [loading, setIsLoading] = React.useState(false)

  const withMeta = React.useCallback((handler: any) => {
    setIsLoading(true)

    return handler()
      .then((response: any) => response)
      .finally(() => setIsLoading(false))
  }, [])

  return [loading, withMeta] as [boolean, (handler: any) => any]
}

interface UseRequestOptions extends RequestInit_ {
  lazy?: boolean
  variables?: { [key: string]: any }
}

export function useRequest<T = any>(
  url: string,
  { lazy = true, variables = {}, ..._options }: UseRequestOptions = {}
) {
  const called = React.useRef(false)

  const [loading, withMeta] = useMeta()

  const [data, setData] = React.useState<T>()

  const [error, setError] = React.useState<Error>()

  const controllerRef = React.useRef<AbortController | null>(null)

  React.useEffect(() => {
    return function cleanup() {
      if (controllerRef.current && loading) {
        controllerRef.current.abort()
      }
    }
  }, [loading])

  const _refetch = (data: { [key: string]: any } = {}) => {
    let { fullUrl, params } = format(url, mergeDeepRight(variables, data))

    if (_options.method === 'GET') {
      fullUrl = `${fullUrl}?${toQueryString(params)}`
    }

    if (controllerRef.current) {
      controllerRef.current.abort()
    }

    controllerRef.current = new AbortController()

    return withMeta(async () => {
      const response = await request<T>(fullUrl, {
        ..._options,
        ...(_options.method !== 'GET' ? { body: data } : {}),
        signal: controllerRef.current?.signal,
        onSuccess: response => {
          if (_options.onSuccess) {
            _options.onSuccess({ data, response })
          }

          setData(response)
        },
        onError: error => {
          if (_options.onError) {
            _options.onError(error)
          }

          setError(error)
        },
      })

      controllerRef.current = null

      return response
    })
  }

  React.useEffect(() => {
    if (!lazy && !called.current) {
      _refetch()

      called.current = true
    }
  }, [lazy, called]) // eslint-disable-line

  return {
    loading,
    mutate: _refetch as (
      v?: { [key: string]: any } | { [key: string]: any }[]
    ) => Promise<T>,
    data,
    error,
    called,
  }
}

const contentTypeIs = (s: string) => {
  return compose(
    ifElse(isNil, identity, (c = '') => c.includes(s)),
    invoker(1, 'get')('content-type'),
    path(['headers'])
  )
}

const convertObjectKeys: any = (
  object: any[] | { [key: string]: any },
  keypath: string[] = []
) => {
  if (!object || typeof object !== 'object') {
    return object
  }

  if (Array.isArray(object)) {
    return object.map((o, index) =>
      convertObjectKeys(o, [...keypath, String(index)])
    )
  }

  return Object.keys(object).reduce((acc: any, key: string) => {
    let value = object[key]

    const nestedKeyPath = [...keypath, key]

    acc[key] = convertObjectKeys(value, nestedKeyPath)
    return acc
  }, {})
}

// 設定上傳檔案的最大 size
function settingMaxSizeConfigToEnv(response: Response) {
  const maxSizeHeader = response.headers.get('Max-Size')
  if (maxSizeHeader && maxSizeHeader !== envStore.uploadMaxSize) {
    envStore.uploadMaxSize = maxSizeHeader
  }
}

function withLangQueryString(url: string) {
  const _url = new URL(
    url.includes('http') || url.includes('https')
      ? url
      : // eslint-disable-next-line no-restricted-globals
        `${new URL(location.href).origin}${url}`
  )

  let params: URLSearchParams = new URLSearchParams(_url.search)

  if (!params.has('lang')) {
    params.append('lang', i18n.language)
  }

  const urlQuery = `${_url.origin}${_url.pathname}?${params.toString()}`
  return urlQuery
}

export function request<T = any>(
  url: string,
  {
    body,
    headers,
    displayError = true,
    withToken = true,
    throwable = true,
    displayMessage = true,
    successText,
    onSuccess,
    onError,
    endpoint,
    ...options
  }: RequestInit_ = {}
): Promise<T> {
  const _url = cond([
    [includes('http'), always(url)],
    [
      () => compose(not, isNil)(endpoint),
      always(pathOr(envStore.apiBaseUrl, [endpoint as any], endpoints) + url),
    ],
    [T, always(envStore.apiBaseUrl + url)],
  ])(url)

  let _fullUrl = _url

  const isForm = body instanceof FormData

  if (!isForm) {
    let formatted = format(_fullUrl, body)
    _fullUrl = formatted.fullUrl

    if (options.method === 'GET') {
      _fullUrl = `${_fullUrl}${toQueryString(formatted.params)}`
    }
  }

  return fetch(withLangQueryString(_fullUrl), {
    ...options,
    body: isForm ? body : JSON.stringify(body),
    headers: merge(
      {
        ...(withToken
          ? {
              Authorization: `Bearer ${localStorageUtil.get(
                'token'
              )}` as NonNullable<string>,
            }
          : {}),
      },
      headers
        ? { ...headers }
        : { 'Content-Type': 'application/json', Accept: 'application/json' }
    ) as Record<string, string>,
  })
    .then(async (response: Response) => {
      if (
        compose(
          and(displayMessage),
          allPass([compose(not, isNil), compose(not, equals(__, 'GET'))]),
          path(['method'])
        )(options)
      ) {
        message({
          content: successText || i18n.t('common:requestSuccess'),
          type: 'success',
        })
      }

      let result: any

      if (response.status === 404) {
        // return Promise.reject(response.statusText)
      }

      settingMaxSizeConfigToEnv(response)

      if (contentTypeIs('application/json')(response)) {
        result = await response.json()
        if (response.status === 401 && result?.error === 'Unauthorized') {
          return Promise.reject({
            message: 'Unauthorized',
            status: 401,
          })
        }
        if (!response.ok) {
          return Promise.reject(result)
        }

        result = convertObjectKeys(result)
      }

      if (contentTypeIs('sheet')(response)) {
        result = await response.blob()
      }

      if (contentTypeIs('.document')(response)) {
        result = await response.blob()
      }

      if (contentTypeIs('zip')(response)) {
        result = await response.blob()
      }

      if (isNil(result)) {
        result = await response.text()
      }

      if (onSuccess) {
        onSuccess(result)
      }

      return result
    })
    .catch((err: Error) => {
      if (displayError && err?.message !== 'Unauthorized') {
        error(err)
      }

      if (onError) {
        onError(err.message as any)
      }

      if (throwable) {
        throw err
      }

      return err
    })
}

export default request
