import { useQuery } from 'react-query'
import { forEach, pathOr, isEmpty, indexBy, prop, toPairs, values } from 'ramda'
import useAuth, { UserSpecialFunctionTypeEnum } from '.'
import type { FunctionAuth, MenuAuth, User, UserPermission } from './auth.types'
import { setUserAuth } from './useLogin'
import useComapnyCode from '@/hooks/useComapnyCode'
import localStorageUtil from '@lib/utils/localstorage'

export const useMineInfo = (
  options: {
    onSuccess?: (user: User) => void
    onError?: (error: any) => void
  } = {}
) => {
  const { onSuccess, onError } = options
  const { setIsAuthenticated, setUser } = useAuth()
  const companyCode = useComapnyCode()

  const { refetch, isLoading } = useQuery(['/account/mineInfo'], {
    cacheTime: Infinity,
    refetchOnMount: false,
    enabled: false,
    onError: error => {
      console.log(error)
      onError && onError(error)
      setIsAuthenticated(false)
    },
    onSuccess: (rs: any) => {
      if (!rs) {
        return
      }
      const response = rs

      const authDetails = pathOr(
        [],
        ['data', 'roleObj', 'authDetails'],
        response
      )

      let authById: { [key: string]: UserPermission } = {}
      let authByUrl: { [key: string]: UserPermission } = {}

      forEach((x: MenuAuth) => {
        authByUrl[x.webPath] = setUserAuth(x, authByUrl[x.webPath])

        if (isEmpty(companyCode) && x.code.startsWith('admin_')) {
          authById[x.name] = setUserAuth(x, authById[x.name])
          return
        }

        if (companyCode && !x.code.startsWith('admin_')) {
          authById[x.name] = setUserAuth(x, authById[x.name])
        }
      })(authDetails)

      const funcList = Object.keys(authById).map(i => authById[i])
      const companyObj: any = pathOr(null, ['data', 'companyObj'], response)
      const memberRoleId: any = pathOr(
        null,
        ['data', 'roleObj', 'id'],
        response
      )
      const sessionInfoId: any = pathOr(
        null,
        ['data', 'sessionInfos', 0, 'id'],
        response
      )

      const { functionAuthByUrl, specialFunctionAuth, functionAuthByCode } =
        prepareFunctionAuth(response, authDetails)

      const userInfo = {
        ...response.data,
        authDetails,
        memberRoleId,
        sessionInfoId,
        authById,
        authByUrl,
        funcList,
        companyCode: companyObj?.companyCode,
        companySeq: companyObj?.id,
        isCompanyAccount: !!companyObj,
        needResetPassword: pathOr(
          null,
          ['data', 'needResetPassword'],
          response
        ),
        functionAuthByUrl,
        specialFunctionAuth,
        functionAuthByCode,
      }

      localStorageUtil.set('user', userInfo)
      setUser(userInfo)
      setIsAuthenticated(true)
      onSuccess && onSuccess(userInfo)
    },
  })

  return { refetch, isLoading }
}

export const useKeepTokenAlive = () => {
  const { refetch } = useQuery(['/account/testAlive'], {
    cacheTime: 60 * 1000,
    enabled: false,
  })
  return {
    refetch,
  }
}

/**
 * functionAuthDetails: 匯入的權限
 */
const prepareFunctionAuth = (response: any, authDetails: any[]) => {
  const functionAuths: FunctionAuth[] = pathOr(
    [],
    ['data', 'roleObj', 'functionAuthDetails'],
    response
  )
  const codeAuthDetailByCode = indexBy(prop('code'), authDetails)

  const functionAuthByCode = indexBy(prop('code'), functionAuths)

  const functionAuthByUrl: any = {}
  toPairs(codeAuthDetailByCode).forEach(([code, auth]: [string, MenuAuth]) => {
    const importFunctionAuth: FunctionAuth | null =
      functionAuthByCode[`${code}_import`]
    // webPath的啟用
    const enableFunctionAuth: FunctionAuth | null =
      functionAuthByCode[`${code}_enable`]
    // webPath的停用
    const disableFunctionAuth: FunctionAuth | null =
      functionAuthByCode[`${code}_disable`]

    functionAuthByUrl[auth.webPath] = {
      import: importFunctionAuth ? importFunctionAuth.authExe : null,
      authEnable: enableFunctionAuth ? enableFunctionAuth.authExe : null,
      authDisable: disableFunctionAuth ? disableFunctionAuth.authExe : null,
    }
  })

  const specialFunctionAuth: { [key: string]: boolean } = {}
  values(UserSpecialFunctionTypeEnum).forEach(authKey => {
    specialFunctionAuth[authKey] = functionAuthByCode[authKey]?.authExe || false
  })

  return {
    functionAuthByUrl,
    specialFunctionAuth,
    functionAuthByCode,
  }
}
