import styled, { css } from 'styled-components'

const Hr = styled.div<{ type?: 'dashed' | 'pointLine' | 'solid' }>`
  margin: 10px 0;
  width: 100%;
  ${({ type = 'pointLine' }) => {
    if (type === 'dashed') {
      return css`
        height: 1px;
        background-color: transparent;
        border-bottom: 1px dashed ${p => p.theme.darkGrey};
      `
    } else if (type === 'pointLine') {
      return css`
        height: 2px;
        background-color: ${p => p.theme.darkGrey};
        display: flex;
        align-items: center;
        &::before {
          content: ' ';
          width: 12px;
          height: 12px;
          border-radius: 6px;
          background-color: ${p => p.theme.darkGrey};
        }
      `
    } else {
      return css`
        height: 1px;
        background-color: transparent;
        border-bottom: 1px solid ${p => p.theme.darkGrey};
      `
    }
  }}
`
export default Hr
