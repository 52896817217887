import { SVGProps } from 'react'

const LoginUserSVG = (props: SVGProps<any>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.998 5.373 18.627.002 12 0zm0 3.6a3.6 3.6 0 1 1 0 7.2 3.6 3.6 0 0 1 0-7.2zm0 17.04a8.64 8.64 0 0 1-7.2-3.864c.036-2.388 4.8-3.696 7.2-3.696 2.4 0 7.164 1.308 7.2 3.696A8.64 8.64 0 0 1 12 20.64z"
        fill="#000"
        fillRule="nonzero"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

export default LoginUserSVG
