import React from 'react'
import Input from '@components/ui/Input'
import { rules } from '@lib/utils/form'
import {
  useChangeCompanyMineAccountPassword,
  useChangeMineAccountPassword,
  useRenewAccountPassword,
  useRenewCompanyAccountPassword,
} from '@lib/sysUser'
import useAuth from '@lib/auth'
import Button from '@components/ui/Button'
import styled from 'styled-components'
import Modal from '@components/ui/Modal'
import Hr from '@components/ui/Hr'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Form, ModalProps } from 'antd'
import { useTranslation } from 'react-i18next'

interface IResetPasswordModalProps extends ModalProps {
  /**
   * Reset 為管理員更改密碼後，於用戶登入時須重設密碼功能
   * Change 為用戶自行更改密碼
   */
  resetMode: 'Reset' | 'Change'
}

const ResetPasswordModal = React.memo<IResetPasswordModalProps>(props => {
  const [form] = Form.useForm()

  const { t } = useTranslation()

  const { onOk, resetMode, onCancel, ...others } = props

  const { user } = useAuth()

  const { mutate: changeMineMutate, isLoading: isChangeMineMutate } =
    useChangeMineAccountPassword()
  const {
    mutate: changeCompanyMineMutate,
    isLoading: isChangeCompanyMineMutate,
  } = useChangeCompanyMineAccountPassword()

  const { mutate: renewAccountMutate, isLoading: isRenewAccountMutate } =
    useRenewAccountPassword()
  const {
    mutate: renewCompanyAccountMutate,
    isLoading: isRenewCompanyAccountMutate,
  } = useRenewCompanyAccountPassword()

  const isAllowClose = resetMode !== 'Reset'

  return (
    <StyledModal
      {...others}
      footer={null}
      width="60%"
      maskClosable={false}
      style={{ maxWidth: '1000px' }}
      closable={isAllowClose}
      closeIcon={
        <CloseCircleOutlined
          onClick={e => {
            onCancel && onCancel(e)
          }}
          style={{ fontSize: '22px', color: '#000' }}
        />
      }
      title={
        <>
          <div className="flex items-center pr-26">
            <label className="text-black mr-20">
              {t('formItems:reset passwordTitle')}
            </label>
            {resetMode === 'Reset' && (
              <label className="text-red mr-10">
                {t('formItems:password prompt')}
              </label>
            )}
          </div>
          <Hr />
        </>
      }>
      <Form
        form={form}
        onFinish={values => {
          const mutate = user.isCompanyAccount
            ? resetMode === 'Reset'
              ? renewCompanyAccountMutate
              : changeCompanyMineMutate
            : resetMode === 'Reset'
            ? renewAccountMutate
            : changeMineMutate
          mutate(
            {
              ...values,
            },
            {
              onSuccess: onOk,
            }
          )
        }}
        colon={false}
        labelAlign="left">
        {resetMode === 'Change' && (
          <Form.Item
            label={t('formItems:password')}
            name="password"
            rules={[rules.password]}>
            <Input.Password
              className="w-220"
              placeholder={t('formItems:input password')}
            />
          </Form.Item>
        )}
        <Form.Item
          label={t('common:new password')}
          name="newPassword"
          rules={[rules.password]}>
          <Input.Password
            className="w-220"
            placeholder={t('common:enter new password')}
          />
        </Form.Item>
        <Form.Item
          label={t('formItems:re-input')}
          name="confirm"
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
              message: t('common:enter password again'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(t('common:password not match')))
              },
            }),
          ]}>
          <Input.Password
            className="w-220"
            placeholder={t('common:enter password again')}
          />
        </Form.Item>
        <Form.Item className="text-right">
          <Button
            htmlType="submit"
            color="primary"
            loading={
              isChangeMineMutate ||
              isChangeCompanyMineMutate ||
              isRenewAccountMutate ||
              isRenewCompanyAccountMutate
            }>
            {t('common:Submit')}
          </Button>
        </Form.Item>
      </Form>
    </StyledModal>
  )
})

export default ResetPasswordModal

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border: 1px solid ${p => p.theme.darkGrey};
    overflow: inherit;
  }
  .ant-modal-body {
    padding: 10px 30px 80px;

    form .ant-form-item-has-error .react-tel-input input {
      border-color: #ff4d4f;
    }
  }
  .ant-modal-header .tabs {
    display: flex;
    position: absolute;
    height: 30px;
    top: -30px;
    left: 0px;
    > div {
      cursor: pointer;
      border: 1px solid ${p => p.theme.silverGrey};
      background-color: ${p => p.theme.lightGrey};
      color: #000;
      font-weight: bold;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      &.active {
        background-color: ${p => p.theme.background};
        color: ${p => p.theme.blue};
      }
    }
  }
`
