import Modal from '@components/ui/Modal'
import { CloseCircleOutlined } from '@ant-design/icons'
import Hr from '@components/ui/Hr'
import { useAnnounceCoeffiDetail } from '@/coefficient'
import React from 'react'
import styled from 'styled-components'
import { includes } from 'ramda'
import Export from 'src/components/Export'

import { BaseTable } from '@components/ui/Table'
import envStore from '@/env'

const AnnouncementCoeffiDetailModal = (props: any) => {
  const {
    payload: { item },
    ...others
  } = props
  const { announceCoefficient } = item

  const { tableTitle, tableDataSource, isLoading } = useAnnounceCoeffiDetail({
    variables: { id: item.coefficientSeq },
    refetchOnMount: true,
  })

  const columns = React.useMemo(
    () =>
      tableTitle
        ? tableTitle.map((i: any, idx: number) => ({
            title: i,
            dataIndex: `col-${idx}`,
            width: includes(idx, [3]) ? 80 : includes(idx, [8, 9]) ? 100 : 220,
            ellipsis: true,
            fixed: idx > 1 ? false : 'left',
          }))
        : [],
    [tableTitle]
  )

  const handleDownloadFile = (apiUrl: string, fileName: string) => {
    const a = document.createElement('a')
    a.href = `${envStore.uploadFileUrlPrefix}${apiUrl}`
    a.download = fileName
    a.click()
  }

  return (
    <Modal
      {...others}
      bodyStyle={{ padding: '0 30px' }}
      maskClosable
      width="100%"
      footer={null}
      closeIcon={
        <CloseCircleOutlined
          onClick={props.onCancel}
          style={{ fontSize: '22px', color: '#000' }}
        />
      }
      title={
        <div>
          <div className="flex justify-between items-center">
            <h2>{announceCoefficient.fileName}</h2>
            <Export
              className="mr-32"
              onClick={() =>
                handleDownloadFile(
                  announceCoefficient?.filePath || '',
                  announceCoefficient?.fileName
                )
              }
              fileName={announceCoefficient?.fileName}
            />
          </div>
          <Hr />
        </div>
      }>
      <StyleBaseTable
        loading={isLoading}
        columns={columns}
        pagination={{ position: ['bottomLeft'] }}
        dataSource={tableDataSource}
        scroll={{ y: '100vh' }}
      />
    </Modal>
  )
}

const StyleBaseTable = styled(BaseTable)`
  .ant-table-thead {
    > tr > th {
      background: #fafafa;
    }
  }
`

export default AnnouncementCoeffiDetailModal
