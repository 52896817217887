import type { MenuAuth, UserPermission } from './auth.types'

export const convertData = (auth: MenuAuth) => {
  return {
    id: auth.id,
    name: auth.subName,
    url: auth.webPath, //submenu need unique id
    icon: auth.icon,
    code: auth.code,
    mainKey: auth.mainKey,
    subKey: auth.subKey,
    isSubMenu: auth.isSub ? auth.isSub : false,
    deletable: auth.authDelete || false,
    creatable: auth.authAdd || false,
    updatable: auth.authEdit || false,
    readable: auth.authView || false,
    parentId: auth.id,
    parentName: auth.name,
    isSubMenuIcon: auth.isSubMenuIcon ? auth.isSubMenuIcon : false,
  } as UserPermission
}
