import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Button from '@components/ui/Button'
import Modal, { ModalProps } from '@components/ui/Modal'

interface Props extends ModalProps {
  onOk: () => void
  onCancel: () => void
  title?: string | React.ReactNode
  okText?: string
}

export default function ConfirmModal({
  onCancel,
  onOk,
  title,
  okText,
  ...props
}: Props) {
  const { t } = useTranslation()

  return (
    <StyledModal
      visible
      width="367px"
      bodyStyle={{ padding: '30px 20px' }}
      closable={false}
      onCancel={onCancel}
      footer={
        <div className="flex justify-between px-50">
          <Button
            key="btn-cancel"
            color="ghost"
            onClick={onCancel}
            style={{ minWidth: 80, height: 35 }}>
            {t('common:Cancel')}
          </Button>
          <Button
            key="btn-ok"
            color="primary"
            style={{ minWidth: 80, height: 35 }}
            onClick={onOk}>
            {okText ? okText : t('common:confirm')}
          </Button>
        </div>
      }
      {...props}>
      <div className="flex justify-center items-center min-h-[100px]">
        <Text>{title}</Text>
      </div>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 9px;
    background-color: #fff;
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #707070;
  }
`

const Text = styled.div`
  font-size: 18px;
  color: #000;
`
