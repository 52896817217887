import { SVGProps } from 'react'

const ListTransparent = (props: SVGProps<any>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M2.25 3C1.007 3 0 3.982 0 5.192c0 1.211 1.007 2.193 2.25 2.193S4.5 6.403 4.5 5.192C4.5 3.982 3.493 3 2.25 3zm0 7.308c-1.243 0-2.25.981-2.25 2.192 0 1.21 1.007 2.192 2.25 2.192S4.5 13.711 4.5 12.5c0-1.21-1.007-2.192-2.25-2.192zm0 7.307c-1.243 0-2.25.982-2.25 2.193C0 21.018 1.007 22 2.25 22s2.25-.982 2.25-2.192c0-1.211-1.007-2.193-2.25-2.193zm21 .731h-15a.74.74 0 0 0-.75.73v1.462c0 .404.336.731.75.731h15a.74.74 0 0 0 .75-.73v-1.462a.74.74 0 0 0-.75-.73zm0-14.615h-15a.74.74 0 0 0-.75.73v1.462c0 .404.336.73.75.73h15a.74.74 0 0 0 .75-.73V4.462a.74.74 0 0 0-.75-.731zm0 7.307h-15a.74.74 0 0 0-.75.731v1.462c0 .403.336.73.75.73h15a.74.74 0 0 0 .75-.73v-1.462a.74.74 0 0 0-.75-.73z"
        fill="#728290"
        fillRule="nonzero"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

export default ListTransparent
