import React from 'react'
import { isEmpty, pathOr, compose, map } from 'ramda'
import { useQuery, UseQueryOptions } from 'react-query'
import useComapnyCode from '@/hooks/useComapnyCode'
import request, {
  convertData,
  Mutation,
  MutationOptions,
  useMakeMutation,
} from '@/services'
import type {
  IAssessment,
  IAssessItem,
  IAssessmentResponse,
} from './assessment.type'
import { ActCategory } from '@/act'
import { uuid } from '@/utils/webHelper'
import { useTranslation } from 'react-i18next'

export const getTotalRating = (items: IAssessItem[]) => {
  let sum = 0
  const temp = [...items]
  temp.forEach(i => {
    i?.details.filter(x => x.checked).map(y => (sum += y.score))
  })
  return sum
}
//取得顯著性評估項目
export const useAssessmentList = (variables?: {}) => {
  const { t } = useTranslation()
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery(
    ['/{companyCode}/salienceEvaluate/result', { companyCode, ...variables }],
    { refetchOnMount: true }
  )

  const dataSource = React.useMemo(() => {
    const temp = pathOr({}, ['data'], data) as IAssessmentResponse

    // #421 - 將全部類別1刪除,其他維持不變
    const filterAssessments = (temp.datas || []).filter(assessment => {
      return ActCategory(assessment.actualActType) !== 'type1'
    })

    if (!isEmpty(temp)) {
      return {
        standardValue: temp?.standardValue,
        data: convertData<IAssessment>(
          {
            field: ['datas'],
            converter: (x, idx) => {
              return {
                ...x,
                sequence: idx + 1,
                categoryName: t(`menu:${ActCategory(x.actualActType)}`),
                totalRating: getTotalRating(x.items),
              }
            },
          },
          { datas: filterAssessments }
        ),
      }
    }
  }, [data])

  return { dataSource, isLoading, refetch }
}

export const useAssessItemList = (
  variables?: {},
  options?: UseQueryOptions<IAssessItem[]>
) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery(
    ['/{companyCode}/salienceEvaluate/item', { companyCode, ...variables }],
    { ...options }
  )

  const dataSource = React.useMemo(
    () =>
      compose(
        map((x: any) => ({ ...x, uuid: uuid() })),
        pathOr([], ['data', 'salienceAssessmentItemDtoList'])
      )(data),
    [data]
  )

  return {
    data: dataSource,
    isLoading,
    refetch,
  }
}

export const useAddAssessItem = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/salienceEvaluate/item', {
        method: 'POST',
        body: {
          ...body,
          companyCode,
        },
      }),
    options
  )
}

export const useAddAssessment = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/salienceEvaluate/result', {
        method: 'POST',
        body: {
          ...body,
          companyCode,
        },
      }),
    options
  )
}
