import React from 'react'
import { Loading } from '@components/Loading'

const LazyLogin = React.lazy(
  () => import(/* webpackChunkName: "Login" */ './AdminLogin')
)

export default function Login() {
  return (
    <React.Suspense fallback={<Loading />}>
      <LazyLogin />
    </React.Suspense>
  )
}
