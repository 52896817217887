import { createGlobalStyle, ThemeProps, css } from 'styled-components'
import theme from './theme'

export const menuTooltips = css`
  .ant-menu-inline-collapsed-tooltip {
    .ant-toolitp-content {
      font-weight: 500 !important;
    }

    .ant-tooltip-arrow-content {
      background-color: ${p => p.theme.menuInlineTooltip.bg} !important;
    }

    .ant-tooltip-inner {
      background: ${p => p.theme.menuInlineTooltip.bg} !important;
      border: 1px solid ${p => p.theme.menuInlineTooltip.bg} !important;
      > a {
        color: ${p => p.theme.menuInlineTooltip.color};
        font-weight: normal;

        &:hover {
          color: ${p => p.theme.menuInlineTooltip.active};
        }
      }
    }
  }
`

export const textUtils = css`
  .text-largest {
    font-size: 18px !important;
  }

  .text-larger {
    font-size: 16px !important;
  }

  .text-default {
    font-size: 14px !important;
  }

  .text-smaller {
    font-size: 13px;
  }

  .text-sm {
    font-size: 12px !important;
  }

  .text-xs {
    font-size: 10px;
  }

  .text-green {
    color: ${p => p.theme.green};
  }
`

const GlobalStyles = createGlobalStyle<ThemeProps<typeof theme>>`
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
      'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-variant: tabular-nums;
    line-height: 1.5;
    font-feature-settings: "tnum","tnum";
    -webkit-font-feature-settings: "tnum";
    -webkit-font-smoothing: subpixel-antialiased;
    background-color: ${p => p.theme.background};


    // disable styles
    *:not(.ant-pagination-item-link, .ant-btn):disabled ,
    .ant-radio-wrapper:has(.ant-radio-disabled) span,
    .ant-checkbox-disabled + span,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-disabled .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item
    {
      color:  ${p => p.theme.disable} !important;

      .ant-select-multiple .ant-select-selection-item-content{
        color:  ${p => p.theme.disable} !important;
      }
    }
  }

  /* ant-design overrides */

  .ant-message {
    top: 64px;
  }

  .ant-modal {
    animation-duration: 0s !important;
    transition: none !important;
  }



  .ant-image-preview-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .anticon.c-icon {
    &.icon-minus-circle svg {
      fill: ${p => p.theme.icon.primary};

      &:hover {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }
  }

  .anticon:hover {
    svg {
      fill: ${p => p.theme.icon.primaryHover};
    }
  }

  svg.svg-icon  {
    cursor:pointer;
    display:inline-block;

    &.is--small,
    &.icon-loading {
        height: 16px;
        width: 16px;
    }

    &.icon-add,
    &.icon-eye,
    &.icon-process,
    &.icon-info {
      > g path:nth-child(2),
      > g path:nth-child(2),
      use {
        fill: ${p => p.theme.icon.primary};
      }
    }

    &.icon-play:hover {
      > g {
        opacity: 1;
      }
      > g > g > g > g > g > g > g > circle {
        stroke: ${p => p.theme.icon.primaryHover};
      }
      > g > g > g > g > g > g > g > g > g path {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-location:hover,
    &.icon-process:hover {
      > g > path:nth-child(2) {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-eye:hover {
      use {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-edit:hover,
    &.icon-minus-circle:hover  {
      g g {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-info:hover {
      > g path:nth-child(3) {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }
  }


  .c-btn.ant-btn {
    border-radius: 3px;
    font-weight: 500;
  }

  .c-btn--primary.ant-btn {
    color: ${p => p.theme.btn.text};
    border-color: ${p => p.theme.btn.bg};
    background: ${p => p.theme.btn.bg};

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.btn.text};
      background: ${p => p.theme.btn.bgHover};
      border-color: ${p => p.theme.btn.bgHover};
    }
  }

  .c-btn--danger.ant-btn {
    color: ${p => p.theme.btn.danger.text};
    border: 1.3px solid ${p => p.theme.btn.danger.border};
    background: transparent;

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.btn.danger.text};
      background: ${p => p.theme.btn.danger.hover};
      border: 1.3px solid ${p => p.theme.btn.danger.border};
    }
  }

  ${textUtils}
  ${menuTooltips}
`

export default GlobalStyles
