import { isEmpty } from 'ramda'
import React from 'react'
import useComapnyCode from './useComapnyCode'

const useCompanyUrl = () => {
  const urlCompanyCode = useComapnyCode()

  const urlReplacer = React.useCallback(
    (url: string, companyCode?: string) => {
      const _companyCode = companyCode || urlCompanyCode
      return isEmpty(_companyCode)
        ? url
        : url.replace(/(:companyCode)/, _companyCode)
    },
    [urlCompanyCode]
  )

  return {
    urlReplacer,
  }
}

export default useCompanyUrl
