import { InputNumber, InputNumberProps } from 'antd'
import { CSSProperties } from 'react'
import styled from 'styled-components'
import { CloseCircleFilled } from '@ant-design/icons'
import { isNil, not } from 'ramda'

export type IEnhanceInputNumberProps = {
  allowClear?: boolean
  inputStyle?: CSSProperties | undefined
  inputClassName?: string
}

const EnhanceInputNumber = (
  props: InputNumberProps & IEnhanceInputNumberProps
) => {
  const {
    allowClear,
    inputStyle,
    inputClassName = '',
    className,
    style,
    value,
    onChange,
    ...others
  } = props

  return (
    <Container className={className} style={style}>
      <StyledInputNumber
        className={`w-full ${inputClassName}`}
        style={inputStyle}
        onChange={onChange}
        value={value}
        {...others}
      />
      {not(isNil(value)) && allowClear ? (
        <div
          style={{
            zIndex: 2,
            position: 'absolute',
            right: '25px',
            top: 0,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
          <CloseCircleFilled
            onClick={() => {
              onChange && onChange(undefined as any)
            }}
            style={{
              pointerEvents: 'auto',
              opacity: 0.8,
            }}
          />
        </div>
      ) : null}
    </Container>
  )
}

const StyledInputNumber = styled(InputNumber).attrs({
  min: 0 as any, // dont know why error
})`
  &.ant-input-number {
    border-radius: 4px;
  }

  &.ant-input-number-focused,
  &.ant-input-number:focus,
  &.ant-input-number:hover {
    border-color: ${p => p.theme.primary};
  }

  &.ant-input-number-focused,
  &.ant-input-number:focus {
    box-shadow: none;
  }
  position: relative;
`

const Container = styled.span`
  position: relative;
  display: inline-block;
`
export default EnhanceInputNumber
