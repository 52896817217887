import React from 'react'
import { useQuery } from 'react-query'
import moment from 'moment'
import { pathOr, path, isEmpty, prop } from 'ramda'
import { MutationOptions, request, useMakeMutation } from '@/services'
import { momentToString, toMoment } from '@/utils/webHelper'
import { publicUrl } from '@/env'
import useComapnyCode from '@/hooks/useComapnyCode'

export type Profile = {
  fileHash: string
  filePash: string
  id: number
  name: string
  url: string
  language: string
  plan: string
  expiryDate: moment.Moment | string
  expiryDateStr: string | string
}

export const profileAPI = async (companyCode: string = '') => {
  return request('{companyCode}/system/profile', {})
}

export function useProfile() {
  const companyCode = useComapnyCode()

  const { data, ...others } = useQuery(
    [
      companyCode ? `/{companyCode}/system/profile` : `/system/profile`,
      { companyCode: companyCode || '' },
    ],
    {
      cacheTime: Infinity,
    }
  )
  const profile = React.useMemo(() => {
    if (!data || isEmpty(pathOr({}, ['data'], data))) {
      return {
        fileHash: '',
        filePash: '',
        name: '',
        url: `${publicUrl}/img/app-logo@3x.png`,
        expiryDate: '',
        expiryDateStr: '',
      } as Profile
    }

    const _data = path(['data'], data) as any

    return {
      ..._data,
      url: !!_data.url ? _data.url : `${publicUrl}/img/app-logo@3x.png`,
      language: _data.defaultLang,
      expiryDate: toMoment(_data.expirationAt),
      expiryDateStr: momentToString(toMoment(_data.expirationAt), 'YYYY/MM/DD'),
    } as Profile
  }, [data])

  return {
    ...others,
    profile,
    loading: others.isLoading,
  }
}

type EditProfileBody = {
  defaultLang: string
  fileHash: string
  name: string
}

export const editProfileAPI = (values: Partial<EditProfileBody>) =>
  request('/system/profile', { method: 'POST', body: values })

export const useEditProfile = (options: MutationOptions = {}) => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (values: Partial<EditProfileBody>) =>
      request('/{companyCode}/system/profile', {
        method: 'POST',
        body: { companyCode: companyCode || '', ...values },
      }),
    options
  )
}

type ISystemVersion = {
  apiCommitHash: string
  companyCode: string
  serverUrl: string
  siteName: string
  version: string
}

export function useSystemVersion() {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useQuery<ISystemVersion>(
    ['/{companyCode}/system/version', { companyCode }],
    {
      cacheTime: Infinity,
      select: (res: any) => prop('data', res),
    }
  )
}

type ISystemBusinessConfig = {
  blocControlAirport: boolean
  blocControlAnnounceCoefficient: boolean
  blocControlCustomizeCoefficient: boolean
  blocControlEquipmentType: boolean
  blocControlEsgData: boolean
  blocControlHashtag: boolean
  blocControlLimit: boolean
  blocControlProcessInfo: boolean
  blocControlProdTerminatedInfo: boolean
  blocControlProdUsageInfo: boolean
  blocControlSalienceEvaluate: boolean
}

export function useSystemBusinessConfig() {
  return useQuery<ISystemBusinessConfig>(['/system/businessConfig'], {
    cacheTime: Infinity,
    select: (res: any) => prop('data', res),
  })
}

export default useProfile
