import { useMutation } from 'react-query'
import { request, useLazyQuery, QueryVariables } from '@/services'
import message from '@/utils/message'
import type { MenuAuth, User, UserPermission } from './auth.types'
import { encrypt } from '@/crypto'
import { useMineInfo } from './useMineInfo'
import { useHistory } from 'react-router-dom'
import {
  ADMIN_COMPANY_LIST_MANAGEMENT,
  SIMPLE_MODE_ENTRANCE,
  STATIONARY_COMBUSTION_MANAGEMENT,
} from '@/routes'
import { convertData } from './auth.converter'
import useComapnyCode from '@/hooks/useComapnyCode'
import { path, isNil } from 'ramda'
import localStorageUtil from '@lib/utils/localstorage'
import useSimpleMode from '@/hooks/useSimpleMode'
import useCompanyUrl from '@/hooks/useCompanyUrl'

export const setUserAuth = (auth: MenuAuth, permission: UserPermission) => {
  const data = convertData(auth)
  if (permission) {
    return {
      ...permission,
      children: [...permission.children, data],
    }
  }

  return {
    ...auth,
    ...data,
    url: auth.isSub ? auth.code : auth.webPath, //submenu need unique id
    ...(auth.isSub
      ? {
          children: [
            { ...convertData(auth), parentId: auth.id, parentName: auth.name },
          ],
        }
      : { children: [] }),
  } as UserPermission
}

export const loginAPI = (body: User) => {
  return request('/login', {
    body,
    method: 'POST',
    displayMessage: false,
    onSuccess: () => {
      if (body.remember) {
        body.loginId && localStorageUtil.set('loginId', body.loginId)
        body.password &&
          localStorageUtil.set('password', encrypt(body.password))
      } else {
        localStorageUtil.remove('loginId')
        localStorageUtil.remove('password')
      }
    },
  })
}

export const useLogin = () => {
  const history = useHistory()

  const { refetch: getUserMineInfo } = useMineInfo({
    onSuccess: () => {
      history.push(ADMIN_COMPANY_LIST_MANAGEMENT)
    },
  })

  const { mutate, isLoading, ...rest } = useMutation(loginAPI, {
    onError: error => {
      console.log(error)
    },
    onSuccess: (response: any) => {
      if (!response) {
        return
      }

      if (response?.errorMessage) {
        message({ content: response?.errorMessage, type: 'error' })
        return
      }

      localStorageUtil.set('token', response.token)
      getUserMineInfo()
    },
  })

  return {
    ...rest,
    mutate,
    loading: isLoading,
    login: mutate,
  }
}

export const usePreLogin = ({
  variables = {},
  onSuccess,
  ...options
}: QueryVariables = {}) => {
  const companyCode = useComapnyCode()

  const { data, refetch, isLoading, ...others } = useLazyQuery(
    [
      companyCode ? `/${companyCode}/company/preLogin` : `/preLogin`,
      { companyCode, ...variables },
    ],
    {
      enabled: !!variables.loginId,
      select: (res: any) => path(['data', 'hasLogin'], res),
      onError: error => {
        console.log(error)
      },
      onSuccess: (response: any) => {
        if (isNil(response)) {
          return
        }

        if (response?.errorMessage) {
          message({ content: response?.errorMessage, type: 'error' })
          return
        }

        onSuccess && onSuccess(response)
      },
      ...options,
    }
  )

  return {
    ...others,
    handlePreLogin: refetch,
    loading: isLoading,
  }
}

export const useLogout = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const { refetch, isLoading } = useLazyQuery(
    ['/logout', { ...variables }],
    options
  )

  return { isLoading, handleLogout: refetch }
}

export const useGlobalPreLogin = ({
  variables = {},
  onSuccess,
  ...options
}: QueryVariables = {}) => {
  const { data, refetch, isLoading, ...others } = useLazyQuery(
    ['/global/preLogin', variables],
    {
      enabled: !!variables.loginId,
      select: (res: any) => path(['data', 'hasLogin'], res),
      onError: error => {
        console.log(error)
      },
      onSuccess: (response: any) => {
        if (isNil(response)) {
          return
        }

        if (response?.errorMessage) {
          message({ content: response?.errorMessage, type: 'error' })
          return
        }

        onSuccess && onSuccess(response)
      },
      ...options,
    }
  )

  return {
    ...others,
    handlePreLogin: refetch,
    loading: isLoading,
  }
}

export const globalLoginAPI = (body: User) => {
  return request('/global/login', {
    body,
    method: 'POST',
    displayMessage: false,
    onSuccess: () => {
      if (body.remember) {
        body.loginId && localStorageUtil.set('loginId', body.loginId)
        body.password &&
          localStorageUtil.set('password', encrypt(body.password))
      } else {
        localStorageUtil.remove('loginId')
        localStorageUtil.remove('password')
      }
    },
  })
}

export const useGlobalLogin = () => {
  const history = useHistory()

  const { isUsedSimpleModeByUser } = useSimpleMode()

  const companyUrl = useCompanyUrl()

  const directToPage = (userInfo: any) => {
    let defaultPage = ''
    // if (!userInfo?.regionObj && !userInfo?.companyObj) {
    //   defaultPage = GLOBAL_REGION_LIST
    // }
    // if (userInfo?.regionObj) {
    //   defaultPage = ADMIN_COMPANY_LIST_MANAGEMENT
    // }
    if (userInfo?.companyObj) {
      if (isUsedSimpleModeByUser(userInfo)) {
        defaultPage = companyUrl.urlReplacer(
          SIMPLE_MODE_ENTRANCE,
          userInfo.companyObj?.companyCode
        )
      } else {
        defaultPage = companyUrl.urlReplacer(
          STATIONARY_COMBUSTION_MANAGEMENT,
          userInfo.companyObj?.companyCode
        )
      }
    }

    history.push(defaultPage)
  }

  const { refetch: getUserMineInfo } = useMineInfo({
    onSuccess: (userInfo: any) => {
      directToPage(userInfo)
    },
  })

  const { mutate, isLoading, ...rest } = useMutation(globalLoginAPI, {
    onError: error => {
      console.log(error)
    },
    onSuccess: (response: any) => {
      if (!response) {
        return
      }

      if (response?.errorMessage) {
        message({ content: response?.errorMessage, type: 'error' })
        return
      }

      localStorageUtil.set('token', response.token)
      // global, admin, company 都使用同一登入頁面，
      // 但不同版面的 style 設定都是由 URL 判斷，故登入後必須在取得 mineInfo 先跳轉到對應的網址
      directToPage(response)
      getUserMineInfo()
    },
  })

  return {
    ...rest,
    mutate,
    loading: isLoading,
    login: mutate,
  }
}

export default useLogin
