import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button as AntButton } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { pathOr } from 'ramda'
import { useAuth, useLogout } from '@/auth'
import { IconLoginUser } from '@icons'
import UsageSpecSelect from 'src/components/UsageSpecSelect'
import { usePageHeader } from 'src/hooks/usePageHeader'
import useUpsertModalController from 'src/hooks/useUpsertModalController'
import useNavbarConfig from 'src/hooks/useNavbarConfig'
import Modals from 'src/components/Modals'

import {
  StyledDropdown,
  Header,
  StyledDropdownMenu,
  StyledDropdownMenuItem,
  LoginUser,
} from './style'

type Props = {
  title?: string | React.ReactNode
}

function Navbar({ title }: Props) {
  const { logout, user } = useAuth()

  const { handleLogout } = useLogout({
    onSuccess: data => {
      logout()
    },
  })

  const { t } = useTranslation()

  const [visibleInfoModal, setVisibleInfoModal] = React.useState(false)
  const [visibleLanguageModal, setVisibleLanguageModal] = React.useState(false)

  const { specSelectConfig } = useNavbarConfig()

  const {
    openInsertModal: openResetPasswordModal,
    isVisible,
    upsertModalProps: resetPasswordModalProps,
    closeModal: closeResetPasswordModal,
  } = useUpsertModalController<void>()

  const handleChangeSpec = (val: any) => {
    specSelectConfig?.setSpecId(val)
  }

  return (
    <Header>
      {isVisible && (
        <Modals.ResetPasswordModal
          resetMode="Change"
          {...resetPasswordModalProps}
          onOk={() => {
            closeResetPasswordModal()
          }}
          onCancel={closeResetPasswordModal}
        />
      )}
      <div>{title}</div>
      <div className="inline-flex items-center">
        {usePageHeader().content}
        <div className="flex items-center">
          {specSelectConfig.isEnable && (
            <UsageSpecSelect
              value={specSelectConfig.selectedSpecId}
              title={
                <label
                  className="mr-16"
                  style={{ fontSize: 16, fontWeight: 'bold', color: '#000' }}>
                  {t('menu:navbar-spec')}
                </label>
              }
              onChange={handleChangeSpec}
            />
          )}
          <span style={{ fontSize: 18 }}>{pathOr('', ['nickname'], user)}</span>
          <StyledDropdown
            trigger={['click']}
            overlay={() => (
              <StyledDropdownMenu>
                <StyledDropdownMenuItem
                  key="ChangePassword"
                  onClick={openResetPasswordModal}>
                  {t('menu:navbar-password')}
                </StyledDropdownMenuItem>
                <StyledDropdownMenuItem
                  key="systemInfo"
                  onClick={() => setVisibleInfoModal(true)}>
                  {t('system:info')}
                </StyledDropdownMenuItem>
                <StyledDropdownMenuItem
                  key="selectLanguage"
                  onClick={() => setVisibleLanguageModal(true)}>
                  {t('menu:Select Language')}
                </StyledDropdownMenuItem>
                <StyledDropdownMenuItem
                  key="SignOut"
                  onClick={() =>
                    handleLogout({ name: pathOr('', ['memberId'], user) })
                  }>
                  {t('common:logout')}
                </StyledDropdownMenuItem>
              </StyledDropdownMenu>
            )}>
            <AntButton type="link" className="ml-18 ant-dropdown-link p-0">
              <LoginUser>
                <IconLoginUser />
                <CaretDownOutlined className="icon-caret-down" />
              </LoginUser>
            </AntButton>
          </StyledDropdown>
        </div>
      </div>
      <Modals.SystemVersionModal
        visible={visibleInfoModal}
        onCancel={() => setVisibleInfoModal(false)}
      />
      <Modals.LanguageModal
        visible={visibleLanguageModal}
        onCancel={() => setVisibleLanguageModal(false)}
        onOk={() => setVisibleLanguageModal(false)}
      />
    </Header>
  )
}

export default Navbar
