import styled from 'styled-components'
import { Input } from 'antd'

const Search = styled(Input.Search)`
  &.ant-input-search {
    .ant-input {
      border-color: ${p => p.theme.whisper};

      &:hover,
      &:focus {
        border-color: ${p => p.theme.primary};
      }

      &:focus {
        box-shadow: none;
      }
    }

    .anticon-search {
      font-size: 20px;
      color: ${p => p.theme.searchIcon};
    }
  }
`

export default Search
