import { SVGProps } from 'react'

const FolderBlack = (props: SVGProps<any>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <g fill="#000" fillRule="nonzero">
        <path d="M22.313 5.25H11.46l-.586-1.826A1.684 1.684 0 0 0 9.267 2.25h-7.58C.755 2.25 0 3.005 0 3.938v16.125c0 .932.755 1.687 1.688 1.687h20.625c.932 0 1.687-.755 1.687-1.688V6.938c0-.932-.755-1.687-1.688-1.687z" />
        <path d="M1.688 3.938h7.58l.419 1.312h-8zM22.313 20.063H1.688V6.938h20.625z" />
      </g>
      <path
        d="M11.992 8.08c-.33 0-.6.105-.825.315-.224.21-.33.48-.33.81 0 .33.106.6.33.825.226.21.495.315.825.315.33 0 .6-.105.825-.315.226-.21.346-.495.346-.825 0-.33-.12-.6-.33-.81a1.192 1.192 0 0 0-.84-.315zm-.854 3.165V19h1.71v-7.755h-1.71z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default FolderBlack
