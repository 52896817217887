import { history } from '@/routes'
import { pathOr } from 'ramda'
import { matchPath } from 'react-router-dom'
import React from 'react'

type WebSystemRole = 'SystemAdmin' | 'Company'

const useWebSystemRole = (): WebSystemRole => {
  const pathResult = matchPath(history.location.pathname, {
    path: '/companies/:companyCode/(.)*',
  })
  const pathCompanyCode = React.useMemo(
    () => pathOr('', ['params', 'companyCode'], pathResult),
    [pathResult]
  )

  return pathCompanyCode === '' ? 'SystemAdmin' : 'Company'
}

export default useWebSystemRole
