import { SVGProps } from 'react'

const FilterSVG = (props: SVGProps<any>) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M21.064 4H2.936a.939.939 0 0 0-.663 1.6l7.226 7.226v8.05c0 .307.15.593.4.769l3.126 2.186a.939.939 0 0 0 1.475-.768V12.826L21.727 5.6a.938.938 0 0 0-.663-1.6z"
        fill="#000"
        fillRule="nonzero"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

export default FilterSVG
