import { ActTypeEnum } from '@/act'

export const STREETLIGHT_MANAGEMENT = '/street-light-management'

export const SWITCHBOX_MANAGEMENT = '/device/switchbox-management'

export const CAMERA_MANAGEMENT = '/device/camera-management'

export const TALKIE_MANAGEMENT = '/device/talkie-management'

export const WDETECT_MANAGEMENT = '/device/wdetect-management'

export const REPAIR_MANAGEMENT = '/facility/repair-list'

export const CLOSED_REPAIR_MANAGEMENT = '/facility/closed-repair-list'

export const MAINTENANCE_MANAGEMENT = '/facility/maintenance-list'

export const MEDIA_SCREEN_MANAGEMENT = '/device/media-screen-management'

export const MAP_MANAGEMENT = '/map-management'

export const STATIONARY_COMBUSTION_MANAGEMENT =
  '/companies/:companyCode/stationary-combustion-management'
export const FIRM_COEFFICIENT_MANAGEMENT =
  '/companies/:companyCode/firm-coeffi-management'
export const USER_MANAGEMENT = '/companies/:companyCode/user-management'
export const ADMIN_COMPANY_LIST_MANAGEMENT = '/admin-company-list'
export const ADMIN_IMPORT_SETTING = 'admin-import-setting'
export const MOVABLE_COMBUSTION_MANAGEMENT =
  '/companies/:companyCode/movable-combustion-management'

export const SIMPLE_MODE_ENTRANCE =
  '/companies/:companyCode/simple-mode-entrance'

export const WELCOME_PAGE = '/companies/:companyCode/welcome'

export const ACTIONS_ROUTES = {
  [ActTypeEnum.StationaryCombustion]: STATIONARY_COMBUSTION_MANAGEMENT,
  [ActTypeEnum.MovableCombustion]: MOVABLE_COMBUSTION_MANAGEMENT,
  [ActTypeEnum.ProcessEmissions]:
    '/companies/:companyCode/process-emissions-management',
  [ActTypeEnum.FugitiveEmission]:
    '/companies/:companyCode/fugitive-emission-management',
  [ActTypeEnum.PowerInput]: '/companies/:companyCode/power-input-management',
  [ActTypeEnum.SteamInput]: '/companies/:companyCode/steam-input-management',
  [ActTypeEnum.Shipment]: '/companies/:companyCode/shipment-management',
  [ActTypeEnum.Attendance]: '/companies/:companyCode/attendance-management',
  [ActTypeEnum.Trip]:
    '/companies/:companyCode/employee-business-trip-management',
  [ActTypeEnum.Visitor]: '/companies/:companyCode/visitors-management',
  [ActTypeEnum.PurchaseGoods]: '/companies/:companyCode/purchase-management',
  [ActTypeEnum.Assets]: '/companies/:companyCode/assets-management',
  [ActTypeEnum.WasteDisposal]:
    '/companies/:companyCode/waste-disposal-management',
  [ActTypeEnum.WasteWaterDisposal]:
    '/companies/:companyCode/waste-water-disposal-management',
  [ActTypeEnum.AlternativeEnergy]:
    '/companies/:companyCode/alternative-energy-management',
  [ActTypeEnum.RentalAssets]:
    '/companies/:companyCode/rental-assets-management',
  [ActTypeEnum.WaterUsage]: '/companies/:companyCode/water-usage-management',
}

export const SSO_COMPANY_VERIFY_PAGE = '/companies/:companyCode/SSO/ssoVerify'
export const COMPANY_ROOT_ROUTE = '/companies/:companyCode'
