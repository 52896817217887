import Modal from '@components/ui/Modal/Modal'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useTranslationConfig } from 'src/hooks/useTranslationConfig'
import { Select } from '@components/ui/Select'
import Hr from '@components/ui/Hr'
import Button from '@components/ui/Button'
import { ModalProps, Form } from 'antd'
import { useTranslation } from 'react-i18next'

const LanguageModal = (props: ModalProps) => {
  const { t } = useTranslation()

  const { Langs, currentLang, setSystemTranslationLang } =
    useTranslationConfig()

  return (
    <Modal
      {...props}
      maskClosable
      footer={null}
      closeIcon={
        <CloseCircleOutlined
          onClick={props.onCancel}
          style={{ fontSize: '22px', color: '#000' }}
        />
      }
      title={
        <div>
          <h2>{t('menu:Select Language')}</h2>
          <Hr />
        </div>
      }>
      <Form
        onFinish={values => {
          setSystemTranslationLang(values?.lang)
          props.onOk && props.onOk(values)
        }}>
        <Form.Item
          name="lang"
          label={t('menu:Select Language')}
          initialValue={currentLang}>
          <Select className="w-180">
            {Langs.map(lang => (
              <Select.Option key={lang} value={lang}>
                {t(`common:${lang}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="text-right">
          <Button htmlType="submit" color="primary">
            {t('common:Submit')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default LanguageModal
