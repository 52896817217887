import { pathOr } from 'ramda'
import history from '@/routes/history'
import { useAuth, UserPermission, ActionTypes } from '@/auth'
import useComapnyCode from '@/hooks/useComapnyCode'

/**
 * authurl：用於內頁權限的判斷與外頁路徑(authByUrl) 不同時
 * @param actionType
 * @param authurl 源路徑
 * @returns
 */

export default function useRouteAuth() {
  const { user } = useAuth()
  const companyCode = useComapnyCode()

  const pathname = history.location.pathname
  const authUrl = companyCode
    ? pathname.replace(companyCode, ':companyCode')
    : pathname

  const routeAuth = pathOr({}, ['authByUrl', authUrl], user) as UserPermission

  const checkIsAuthenticated = (act: ActionTypes, authurl?: string) => {
    if (!authurl) {
      return routeAuth[act]
    }

    const _authUrl = companyCode
      ? authurl.replace(companyCode, ':companyCode')
      : authurl

    const authByUrl = pathOr(
      {},
      ['authByUrl', _authUrl],
      user
    ) as UserPermission

    return authByUrl[act]
  }

  return [routeAuth, checkIsAuthenticated] as [
    UserPermission,
    (act: string, authurl?: string) => boolean
  ]
}
