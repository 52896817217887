import React from 'react'
import { useQuery } from 'react-query'
import { indexBy, pathOr, prop } from 'ramda'
import request, {
  Mutation,
  MutationOptions,
  useMakeMutation,
  convertData,
} from '@/services'
import useComapnyCode from '@/hooks/useComapnyCode'
import type { IRegionCoeffi, IRegionCoeffiDetail } from './region.type'
import { useAcTypeList } from '@/options'
import { CoffiFuel } from './firm.type'

export const useRegionCoeffiList = ({
  variables = {},
  ...options
}: any = {}) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })
  const { byId } = useAcTypeList()

  const { data, refetch, ...rest } = useQuery<IRegionCoeffi[]>(
    [
      '/{companyCode}/coefficient/regionCoefficient/list',
      { companyCode, ...variables },
    ],
    {
      enabled: !!companyCode,
      ...options,
    }
  )

  const dataSource = React.useMemo(
    () =>
      convertData<IRegionCoeffi>(
        {
          field: ['data'],
          converter: x => ({
            ...x,
            actTypeName: byId[x?.actType]?.desc || x?.actType,
          }),
        },
        data
      ),
    [data, byId]
  )

  return {
    ...rest,
    refetch,
    dataSource,
  }
}

export const useRegionCoeffiDetail = ({
  variables = {},
  ...options
}: any = {}) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, refetch, ...others } = useQuery<IRegionCoeffiDetail>(
    [
      '/{companyCode}/coefficient/regionCoefficient/{id}',
      { companyCode, ...variables },
    ],
    {
      enabled: !!variables.id,
      select: (res: any) => prop('data', res),
      refetchOnMount: true,
      ...options,
    }
  )

  return {
    ...others,
    data,
  }
}

export const useAddRegionCoeffi = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: any) =>
      request(`/${companyCode}/coefficient/regionCoefficient`, {
        method: 'POST',
        body: {
          ...body,
        },
      }),
    options
  )
}

export const useUpdateRegionCoeffi = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: any) =>
      request(`/${companyCode}/coefficient/regionCoefficient/{id}`, {
        method: 'PUT',
        body: {
          ...body,
        },
      }),
    options
  )
}

export const useUpdateStatusRegionCoeffi = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: any) =>
      request(`/${companyCode}/coefficient/regionCoefficient/status/{id}`, {
        method: 'PUT',
        body: {
          ...body,
        },
      }),
    options
  )
}

export const useDeleteRegionCoeffi = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: any) =>
      request(`/${companyCode}/coefficient/regionCoefficient/{id}`, {
        method: 'DELETE',
        body: { ...body, companyCode },
      }),
    options
  )
}

export const useRegionCoffiFuelList = ({
  variables = {},
  ...options
}: any = {}) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, refetch, ...rest } = useQuery<CoffiFuel[]>(
    [
      '/{companyCode}/coefficient/regionCoefficient/fuels',
      { companyCode, ...variables },
    ],
    {
      ...options,
      enabled: !!companyCode && !!variables?.actType,
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return indexBy(prop('id'), data || [])
  }, [data])

  return {
    ...rest,
    refetch,
    data,
    byId,
  }
}
