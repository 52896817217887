import { Form } from 'antd'
import styled from 'styled-components'

const FormItem = styled(Form.Item).attrs({
  colon: false,
})`
  &.ant-form-item {
    margin-bottom: 8px;
  }

  &.ant-form-item.is--inline {
    line-height: unset;
    margin-bottom: 0;

    .ant-form-item-control {
      line-height: unset;
    }
  }

  .ant-form-item-no-colon.ant-form-item-required::before {
    display: none;
  }
`

export default FormItem
