import { Layout, Menu, Dropdown } from 'antd'
import styled from 'styled-components'

export const StyledDropdown = styled(Dropdown)`
  .ant-select-selection {
    border: 0;
    height: 100%;
  }

  .ant-select-selection__rendered {
    line-height: unset;
  }

  .ant-select-dropdown-important {
    display: none;
  }
`

export const Header = styled(Layout.Header)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  &.ant-layout-header {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 20px;
    background: ${({ theme }) => theme.background};
    line-height: initial;
    border-top: 4px solid ${({ theme }) => theme.primary};
  }
`

export const StyledDropdownMenu = styled(Menu)`
  padding: 0 !important;
  border-radius: 4px !important;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2) !important;
  border: solid 1px ${({ theme }) => theme.silverGrey} !important;
  background-color: ${({ theme }) => theme.whisper} !important;
  min-width: 104px !important;
`

export const StyledDropdownMenuItem = styled(Menu.Item)`
  font-size: 14px !important;
  color: ${({ theme }) => theme.darkGrey} !important;
  text-align: center;

  &:hover {
    background: ${p => p.theme.table.borderBottomHover} !important;
    color: #fff !important;
  }
`

export const LoginUser = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  font-size: 25px;

  .icon-caret-down {
    position: relative;
    top: 2px;
    margin-left: 4px !important;
    margin-right: 10px !important;
    font-size: 14px !important;
  }

  &:hover svg {
    fill: #707070;
  }
`
