import React, { Suspense } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import useTitle from '@/hooks/useTitle'
import {
  ADMIN_COMPANY_LIST_MANAGEMENT,
  COMPANY_ROOT_ROUTE,
  SIMPLE_MODE_ENTRANCE,
  SSO_COMPANY_VERIFY_PAGE,
  STATIONARY_COMBUSTION_MANAGEMENT,
  WELCOME_PAGE,
  useAppRoutes,
  useRoutes,
} from '@/routes'
import { useProfile } from '@/services/profile'
import GlobalStyles from '@assets/GlobalStyles'
import { Exception, QueryErrorBoundary } from '@components/ErrorBoundary'
import { ScreenLoading, Loading } from '@components/Loading'
import useAuth from '@lib/auth'
import { useMineInfo } from '@lib/auth/useMineInfo'
import envStore from '@lib/env'
import useComapnyCode from '@lib/hooks/useComapnyCode'
import useCompanyUrl from '@lib/hooks/useCompanyUrl'
import useSimpleMode from '@lib/hooks/useSimpleMode'
import useWebSystemRole from '@lib/hooks/useWebSystemRole'
import { Layout } from 'antd'
import { isEmpty } from 'ramda'
import { useQueryClient } from 'react-query'
import Navbar from 'src/components/Navbar'
import SideMenu from 'src/components/SideMenu'
import AdminLogin from 'src/containers/AdminLogin'
import CompanyLogin from 'src/containers/CompanyLogin'
import useKeepAlive from './hooks/useKeepAlive'
import { useTranslationConfig } from './hooks/useTranslationConfig'
import useUpsertModalController from './hooks/useUpsertModalController'
import * as routes from './routes'

import Modals from 'src/components/Modals'
import {
  AssessCategoriesManagement,
  AssessItemsManagement,
  SimpleModeEntrance,
  Welcome,
} from './routes'
import { usePagePermission } from './hooks/usePagePermission'
import useModalVisible from '@lib/hooks/useModalVisible'
import localStorageUtil from '@/utils/localstorage'
import { usePageTitle } from './hooks/usePageTitle'
import Login from './containers/SingleEntry'

const { compose } = require('ramda')

function App() {
  const { profile, isFetching } = useProfile()

  const { initialSystemTranslation } = useTranslationConfig()

  React.useEffect(() => {
    initialSystemTranslation()
  }, [initialSystemTranslation])

  useTitle(profile?.name ?? '')

  const { user } = useAuth()

  const { refetch: getUserMineInfo } = useMineInfo()

  const queryClient = useQueryClient()

  useKeepAlive()

  const {
    openInsertModal: openResetPasswordModal,
    isVisible,
    upsertModalProps: resetPasswordModalProps,
    closeModal: closeResetPasswordModal,
  } = useUpsertModalController<void>()

  const [
    visiblePageNoPermission,
    handleVisiblePageNoPermission,
    handleClosePageNoPermission,
  ] = useModalVisible()

  React.useEffect(() => {
    if (user?.needResetPassword) {
      openResetPasswordModal()
    } else {
      closeResetPasswordModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  React.useEffect(() => {
    queryClient.invalidateQueries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  React.useEffect(() => {
    if (localStorageUtil.get('token')) {
      getUserMineInfo()
    }
  }, [getUserMineInfo])

  const companyCode = useComapnyCode()

  const webSystemRole = useWebSystemRole()

  const companyUrl = useCompanyUrl()

  const { isAuthenticated, isMobile, layoutStyle, renderRoute } = useAppRoutes()

  const history = useHistory()

  const { _routes, routeByUrl } = useRoutes(routes)

  const { urlReplacer } = useCompanyUrl()

  const PageTitle = usePageTitle({
    pathname: history.location.pathname,
    routeByUrl,
    urlReplacer,
  })

  // 若 URL 的 companyCode 與當前登入用戶的 companyCode 不同時
  // 則以用戶的 companyCode 為基準，自動挑轉至正確的 companyCode URL
  React.useEffect(() => {
    if (webSystemRole === 'SystemAdmin') {
      return
    }
    if (!user || !companyCode || !user.companyCode) {
      return
    }
    if (user.companyCode !== companyCode) {
      history.push(
        companyUrl.urlReplacer(
          STATIONARY_COMBUSTION_MANAGEMENT,
          user.companyCode
        )
      )
    }
  }, [companyCode, companyUrl, history, user, webSystemRole])

  const { isUsedSimpleMode } = useSimpleMode()

  // 自動跳轉階層三以後的首頁至固定式燃燒源
  React.useEffect(() => {
    if (!user) {
      return
    }
    if (webSystemRole === 'SystemAdmin') {
      return
    }
    const reg = new RegExp(
      companyUrl.urlReplacer('/companies/:companyCode') + '(/)?$'
    )
    if (!reg.test(history.location.pathname)) {
      return
    }
    if (isUsedSimpleMode) {
      history.push(companyUrl.urlReplacer(SIMPLE_MODE_ENTRANCE))
    } else {
      history.push(companyUrl.urlReplacer(STATIONARY_COMBUSTION_MANAGEMENT))
    }
  }, [companyUrl, history, user, webSystemRole, isUsedSimpleMode])

  // 自動跳轉階層1,2 的首頁至客戶清單
  React.useEffect(() => {
    if (!user || !!user.companyCode) {
      return
    }
    if (history.location.pathname === '/') {
      history.push(ADMIN_COMPANY_LIST_MANAGEMENT)
    }
  }, [history, user])

  const { hasPagePermission } = usePagePermission()

  const handleClosedPageNoPermission = () => {
    if (isUsedSimpleMode) {
      history.push(companyUrl.urlReplacer(SIMPLE_MODE_ENTRANCE))
    } else {
      history.push(companyUrl.urlReplacer(STATIONARY_COMBUSTION_MANAGEMENT))
    }
    handleClosePageNoPermission()
  }

  React.useEffect(() => {
    !hasPagePermission && handleVisiblePageNoPermission()
  }, [hasPagePermission])

  return (
    <QueryErrorBoundary>
      <GlobalStyles />
      {isFetching && <ScreenLoading />}
      {isVisible && (
        <Modals.ResetPasswordModal
          resetMode="Reset"
          {...resetPasswordModalProps}
          onOk={() => {
            closeResetPasswordModal()
          }}
        />
      )}

      {visiblePageNoPermission && (
        <Modals.PageNoPermissionModal
          onCancel={handleClosedPageNoPermission}
          onOk={handleClosedPageNoPermission}
        />
      )}

      {isAuthenticated ? (
        <Switch>
          <Route
            exact
            path="/companies/:companyCode/login"
            component={CompanyLogin}
          />
          <Route exact path="/login" component={AdminLogin} />
          <Route
            path="/companies/:companyCode/simple-mode-entrance"
            component={SimpleModeEntrance}
          />
          <Route
            path={SSO_COMPANY_VERIFY_PAGE}
            exact
            component={() => <Redirect to={urlReplacer(COMPANY_ROOT_ROUTE)} />}
          />
          <>
            <Layout style={layoutStyle}>
              {/* <Header /> */}
              <Layout style={layoutStyle}>
                <SideMenu />
                <Layout.Content style={layoutStyle}>
                  <Suspense fallback={<Loading />}>
                    <Navbar title={PageTitle} />
                    <Switch>
                      <Route path={WELCOME_PAGE} component={Welcome} />
                      {hasPagePermission && (
                        <>
                          {' '}
                          {renderRoute({ data: _routes })}
                          <Route
                            path="/companies/:companyCode/assess-management/categories"
                            component={AssessCategoriesManagement}
                          />
                          <Route
                            path="/companies/:companyCode/assess-management/items"
                            component={AssessItemsManagement}
                          />
                          <Route
                            path="/admin-assess-management/categories"
                            component={AssessCategoriesManagement}
                          />
                          <Route
                            path="/admin-assess-management/items"
                            component={AssessItemsManagement}
                          />
                        </>
                      )}
                      <Route component={Exception} />
                    </Switch>
                  </Suspense>
                </Layout.Content>
              </Layout>
            </Layout>
          </>
        </Switch>
      ) : (
        <>
          <Switch>
            {envStore.isSSOEnable ? (
              <Route
                path={SSO_COMPANY_VERIFY_PAGE}
                exact
                component={routes.SSOVerify}
              />
            ) : null}
            <Route path="*" component={LoginPage} />
          </Switch>
        </>
      )}
    </QueryErrorBoundary>
  )
}

const LoginPage = () => {
  const companyCode = useComapnyCode()
  // 雙入口
  if (envStore.disableSingleEntry) {
    return isEmpty(companyCode) ? (
      <Layout>
        <AdminLogin />
      </Layout>
    ) : (
      <Layout>
        <CompanyLogin />
      </Layout>
    )
  } else {
    // 單一入口
    return (
      <Layout>
        <Login />
      </Layout>
    )
  }
}

export default App
