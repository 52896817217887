import { approvalCompanyCode } from '@/constants'
import { IUpsertModalMode } from '@components/ui/UpsertModal'
import useSimpleMode from '@lib/hooks/useSimpleMode'
import { isNil } from 'ramda'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

export default function useUpsertModalController<PayloadType>(options?: {
  openInsertModalOnSimpleAddMode?: boolean
}) {
  const setApprovalCompanyCode = useSetRecoilState<
    { [key: string]: any } | undefined
  >(approvalCompanyCode)

  const [upsertModalProps, setUpsertModalProps] = React.useState<{
    payload?: PayloadType
    mode?: IUpsertModalMode
    visible?: boolean
  }>({})

  const openInsertModal = React.useCallback(() => {
    setUpsertModalProps({
      mode: 'insert',
      payload: undefined,
      visible: true,
    })
  }, [])

  const openUpdateModal = React.useCallback((payload: PayloadType) => {
    setUpsertModalProps({
      mode: 'update',
      payload,
      visible: true,
    })
  }, [])

  const openViewerModal = React.useCallback((payload: PayloadType) => {
    setUpsertModalProps({
      mode: 'viewer',
      payload,
      visible: true,
    })
  }, [])

  const openModal = React.useCallback(
    (mode: IUpsertModalMode, payload: PayloadType) => {
      setUpsertModalProps({
        mode,
        payload,
        visible: true,
      })
    },
    []
  )

  const closeModal = React.useCallback(() => {
    setUpsertModalProps({})
    setApprovalCompanyCode(undefined)
  }, [])

  const isVisible = React.useMemo(() => {
    if (upsertModalProps.mode === 'insert') {
      return true
    }
    if (
      upsertModalProps.mode === 'update' &&
      !isNil(upsertModalProps.payload)
    ) {
      return true
    }
    if (
      upsertModalProps.mode === 'viewer' &&
      !isNil(upsertModalProps.payload)
    ) {
      return true
    }
    return false
  }, [upsertModalProps.mode, upsertModalProps.payload])

  const { state, isUsedSimpleMode } = useSimpleMode()

  const history = useHistory()

  React.useEffect(() => {
    if (
      !isUsedSimpleMode ||
      !options?.openInsertModalOnSimpleAddMode ||
      !state.isAddedMode ||
      !state.actSimpleFillinData
    ) {
      return
    }

    const searchParams = new URLSearchParams(history.location.search)
    if (
      Number(searchParams.get('actType')) === state.actSimpleFillinData.actType
    ) {
      openInsertModal()
    }
  }, [
    history.location.search,
    isUsedSimpleMode,
    openInsertModal,
    options?.openInsertModalOnSimpleAddMode,
    state.actSimpleFillinData,
    state.isAddedMode,
  ])

  return {
    upsertModalProps,
    openInsertModal,
    openUpdateModal,
    openViewerModal,
    openModal,
    closeModal,
    isVisible,
  }
}
