import { SVGProps } from 'react'

const Download = (props: SVGProps<any>) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M22 15.333v4.444A2.223 2.223 0 0 1 19.777 22H4.223A2.223 2.223 0 0 1 2 19.777v-4.444M6.444 9.777 12 15.333l5.556-5.556M12 15.333V2"
        stroke="#000"
        strokeWidth="2.667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

export default Download
