import { path, toPairs } from 'ramda'
import { RouteItem } from '@/routes'
import theme from '@assets/theme'
import { IConstantRouters } from 'src/routes-constant'
import { useTranslation } from 'react-i18next'
const { compose } = require('ramda')

type Props = {
  pathname: string
  routeByUrl: any
  urlReplacer: any
}

export function usePageTitle({ pathname, routeByUrl, urlReplacer }: Props) {
  const { t } = useTranslation('menu')
  const companyRouteByUrl: Record<string, RouteItem | IConstantRouters> = {}

  toPairs(routeByUrl).forEach(([key, url]) => {
    companyRouteByUrl[urlReplacer(key)] = url
  })
  // toPairs(ConstantRouters).forEach(([key, url]) => {
  //   companyRouteByUrl[urlReplacer(key)] = url
  // })

  return compose((route: RouteItem) => {
    const goodsPageTitle =
      route?.path?.indexOf('/goods-management') > -1 &&
      `${t(route?.mainKey)} > ${t('purchase-management')} > ${route?.title}`
    const employeeCommutePageTitle =
      route?.path?.indexOf('/employee-commute-management') > -1 &&
      `${t(route?.mainKey)} > ${t('attendance-management')} > ${route?.title}`

    return (
      <span style={{ fontSize: '20px', color: theme.darkBlue }}>
        {!route?.isSubMenu
          ? route?.title || ''
          : goodsPageTitle || employeeCommutePageTitle
          ? goodsPageTitle || employeeCommutePageTitle
          : `${t(route?.mainKey)} > ${route?.title}`}
      </span>
    )
  }, path([pathname]))(companyRouteByUrl)
}
