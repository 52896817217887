import { includes } from 'ramda'

export const ActTypeEnum = {
  /**
   * 固定式燃燒源
   */
  StationaryCombustion: 1,
  /**
   * 移動式燃燒源
   */
  MovableCombustion: 2,
  /**
   * 製程排放
   */
  ProcessEmissions: 3,
  /**
   * 逸散排放
   */
  FugitiveEmission: 4,
  /**
   * 土地變更
   */
  LandChanged: 5,
  /**
   * 輸入電力
   */
  PowerInput: 6,
  /**
   * 輸入蒸氣
   */
  SteamInput: 7,
  /**
   *   貨物運輸
   */
  Shipment: 8,
  /**
   * 員工出勤
   */
  Attendance: 9,
  /**
   * 員工差旅
   */
  Trip: 10,
  /**
   *  訪客紀錄
   */
  Visitor: 11,
  /**
   * 採購貨物
   */
  PurchaseGoods: 12,
  /**
   * 資產管理
   */
  Assets: 13,
  /**
   * 廢棄物處理
   */
  WasteDisposal: 14,
  /**
   * 其他能源活動
   */
  AlternativeEnergy: 15,
  /**
   * 資產租賃
   */
  RentalAssets: 16,
  /**
   * 購買服務
   */
  PurchaseServices: 17,
  /**
   * 用水資料
   */
  WaterUsage: 18,
  /**
   * 產品週期
   */
  ProductCycle: 19,
  /**
   * 下游租賃資產
   */
  DownstreamLeasedAssets: 20,
  /**
   * 投資管理
   */
  Investment: 21,
  /**
   * 其他
   */
  Other: 22,
  /**
   *   售出產品的使用
   */
  SoldProduct: 23,
  /**
   *   售出產品最終處理
   */
  SoldProductWaste: 24,
  /**
   *   融資排放
   */
  FinancedEmissions: 25,
  /**
   * 廢水處理
   */
  WasteWaterDisposal: 26,
  GreenElectricityCert: 27,
  SelfPower: 28,
}

/**
 * using api
 */
export const ActCategory = (atype: number) =>
  includes(atype, [
    ActTypeEnum.StationaryCombustion,
    ActTypeEnum.MovableCombustion,
    ActTypeEnum.ProcessEmissions,
    ActTypeEnum.FugitiveEmission,
    ActTypeEnum.LandChanged,
  ])
    ? 'type1'
    : includes(atype, [ActTypeEnum.PowerInput, ActTypeEnum.SteamInput])
    ? 'type2'
    : includes(atype, [
        ActTypeEnum.Shipment,
        ActTypeEnum.Attendance,
        ActTypeEnum.Trip,
        ActTypeEnum.Visitor,
      ])
    ? 'type3'
    : includes(atype, [
        ActTypeEnum.PurchaseGoods,
        ActTypeEnum.Assets,
        ActTypeEnum.WasteDisposal,
        ActTypeEnum.AlternativeEnergy,
        ActTypeEnum.RentalAssets,
      ])
    ? 'type4'
    : includes(atype, [
        ActTypeEnum.PurchaseServices,
        ActTypeEnum.WaterUsage,
        ActTypeEnum.DownstreamLeasedAssets,
        ActTypeEnum.SoldProduct,
        ActTypeEnum.SoldProductWaste,
        ActTypeEnum.FinancedEmissions,
        ActTypeEnum.WasteWaterDisposal,
      ])
    ? 'type5'
    : 'undefined'

export const ActCategoryStr: { [key: number]: string } = {
  1: 'type1',
  2: 'type2',
  3: 'type3',
  4: 'type4',
  5: 'type5',
}

export const RentalActTypes: number[] = [1, 2, 3, 4, 6, 7]

/**
 * 限值設定 - 排除的選項
 */
export const ExcludedLimitSelect: number[] = [
  12, 15, 16, 18, 20, 21, 22, 23, 24, 25,
]
/**
 *
 * 統計表
 */
export const ScopeName = (mType: number) => {
  return includes(mType, [1])
    ? 'scope1'
    : includes(mType, [2])
    ? 'scope2'
    : 'scope3'
}

export const ApprovalActionTypeEnums = {
  /**
   * 簽核
   */
  Approval: 0,
  /**
   * 駁回
   */
  Reject: 1,
  /**
   * 抽單
   */
  Draw: 2,
  /**
   * 取消申請
   */
  Cancel: 3,
}

export const ApprovalReviewTypeDisplayEnums: any = {
  /**
   * 使用在 common.json
   */
  0: 'Add',
  /**
   * 編輯
   */
  1: 'Edit',
  /**
   * 刪除
   */
  2: 'Delete',
}

export const ApprovalReviewActionEnums = {
  /**
   * 申請
   */
  Apply: 0,
  /**
   * 簽核
   */
  Approval: 1,
  /**
   * 駁回
   */
  Reject: 2,
  /**
   * 抽單
   */
  Draw: 3,
  /**
   * 取消申請
   */
  Cancel: 4,
}

// 0:申請;1:批淮;2:駁回;3:抽單;4:取消申請;
export const ApprovalReviewActionDisplayEnums: { [key: number]: string } = {
  /**
   * 使用在 common.json
   * 申請
   */
  [ApprovalReviewActionEnums.Apply]: 'ApplyFor',
  /**
   * 簽核
   */
  [ApprovalReviewActionEnums.Approval]: 'Approval',
  /**
   * 駁回
   */
  [ApprovalReviewActionEnums.Reject]: 'Reject',
  /**
   * 抽單
   */
  [ApprovalReviewActionEnums.Draw]: 'Draw',
  /**
   *取消申請
   */
  [ApprovalReviewActionEnums.Cancel]: 'CancelApply',
}

export const ActModifyAPI: any = {
  ['type1']: '/{companyCode}/act/type1/activity/modify',
  ['type2']: '/{companyCode}/act/type2/activity/modify',
  [ActTypeEnum.PowerInput]:
    '/{companyCode}/act/type2/activity/inputPower/modify',
  [ActTypeEnum.Shipment]:
    '/{companyCode}/act/type3/activity/actGoodsTrans/modify',
  [ActTypeEnum.Visitor]:
    '/{companyCode}/act/type3/activity/actGuestRecord/modify',
  [ActTypeEnum.Attendance]:
    '/{companyCode}/act/type3/activity/actEmployeeAttendance/modify',
  [ActTypeEnum.Trip]:
    '/{companyCode}/act/type3/activity/actEmployeeTravel/modify',
  [ActTypeEnum.PurchaseGoods]:
    '/{companyCode}/act/type4/activity/actPurchaseGoods/modify',
  [ActTypeEnum.Assets]:
    '/{companyCode}/act/type4/activity/actAssetManagement/modify',
  [ActTypeEnum.WasteDisposal]:
    '/{companyCode}/act/type4/activity/actScrap/modify',
  [ActTypeEnum.WasteWaterDisposal]:
    '/{companyCode}/act/type4/activity/actScrapWater/modify',
  [ActTypeEnum.WaterUsage]:
    '/{companyCode}/act/type4/activity/actWaterUsage/modify',
  [ActTypeEnum.Investment]:
    '/{companyCode}/act/type5/activity/actInvestment/modify',
  [ActTypeEnum.SoldProduct]:
    '/{companyCode}/act/type5/activity/actProdUsage/modify',
  [ActTypeEnum.SoldProductWaste]:
    '/{companyCode}/act/type5/activity/actProdTerminated/modify',
  [ActTypeEnum.DownstreamLeasedAssets]:
    '/{companyCode}/act/type5/activity/actAssetLease/modify',
  [ActTypeEnum.FinancedEmissions]:
    '/{companyCode}/act/type5/activity/actFinancing/modify',
  [ActTypeEnum.GreenElectricityCert]:
    '/{companyCode}/greenElectricityCert/modify',
  [ActTypeEnum.SelfPower]: '/{companyCode}/selfPower/modify',
  [ActTypeEnum.Other]: '/{companyCode}/act/type6/activity/actOthers/modify',
}
