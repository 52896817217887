import React from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'
import { merge } from 'ramda'
import { ModalProps } from 'antd/lib/modal'
import { theme } from '@assets/index'
import BaseModal from './Modal.base'

const StyledModal = styled(BaseModal)`
  .ant-modal-header {
    padding-left: 20px;
    padding-top: 20px;
    border-bottom: none;
  }

  .ant-modal-content {
    border: none;
    overflow: hidden;
  }

  .ant-modal-close-x {
    width: 54px;
    height: 43px;
    line-height: 43px;
    font-size: 26px;
  }

  &.header--no-underline .ant-modal-header {
    border-bottom-color: transparent;
  }

  .ant-modal-title {
    font-size: 18px;
    color: ${p => p.theme.modal.title};
  }

  .ant-modal-title .ant-breadcrumb > span:first-child {
    font-size: 18px;
    font-weight: 500;
    color: ${p => p.theme.modal.title};
  }

  .ant-modal-title .ant-breadcrumb > span:last-child {
    font-size: 18px;
    color: ${p => p.theme.modal.title};
    font-weight: normal;
  }

  .ant-modal-title .ant-breadcrumb-separator {
    font-weight: 500;
    color: ${p => p.theme.modal.title};
  }

  .ant-modal-close-icon {
    color: ${p => p.theme.modal.title};
  }

  .ant-modal-body {
    padding: 40px 30px 80px 30px;
  }

  .ant-modal-footer {
    padding: 0 30px 24px 30px;
    border-top: none;

    button {
      height: 32px;
      line-height: 1;
      font-size: 16px;
      min-width: 80px;
    }
  }

  .ant-modal-footer--space-between {
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
  }

  .ant-modal-footer--right {
    display: flex;
    justify-content: flex-end;
    padding-left: 30px;
  }

  .ant-table:not(&.is--normal-size) .ant-table-tbody > tr > td {
    padding-top: 6px;
    padding-bottom: 6px;

    .ant-form-item {
      margin-bottom: 3px;
    }
  }

  .ant-form-item-label .ant-form-item-no-colon:not(.ant-form-item-required) {
    padding-left: 0px;
  }

  &.form-item--is-no-margin-bottom {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  span.anticon-close-circle {
    svg {
      fill: ${p => p.theme.modal.close.color};
    }

    :hover {
      svg {
        fill: ${p => p.theme.modal.close.hover};
      }
    }
  }
`

interface Props extends ModalProps {
  loadingData?: boolean
  children?: React.ReactNode
}

export default function Modal({
  loadingData = false,
  children,
  maskStyle,
  ...props
}: Props) {
  let maskStyleObj = React.useMemo(
    () => merge({ background: theme.modal.mask }, maskStyle as any),
    [maskStyle]
  )

  return (
    <StyledModal
      maskClosable={false}
      maskStyle={maskStyleObj}
      keyboard={false}
      className="header--no-underline"
      {...props}>
      <Spin spinning={loadingData}>{children}</Spin>
    </StyledModal>
  )
}

Modal.confirm = BaseModal.confirm
Modal.error = BaseModal.error
Modal.success = BaseModal.success
Modal.info = BaseModal.info
