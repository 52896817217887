import { SVGProps } from 'react'

const List = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M27.188 27.5H2.813A2.812 2.812 0 0 1 0 24.688V4.063A2.813 2.813 0 0 1 2.813 1.25h24.375A2.812 2.812 0 0 1 30 4.063v20.625a2.812 2.812 0 0 1-2.812 2.812zM7.5 6.406a2.344 2.344 0 1 0 0 4.688 2.344 2.344 0 0 0 0-4.688zm0 5.625a2.344 2.344 0 1 0 0 4.688 2.344 2.344 0 0 0 0-4.688zm0 5.625a2.344 2.344 0 1 0 0 4.688 2.344 2.344 0 0 0 0-4.688zm16.875-7.969V7.813a.7.7 0 0 0-.7-.7H11.953a.7.7 0 0 0-.7.7v1.875a.7.7 0 0 0 .7.7h11.719a.7.7 0 0 0 .703-.7v-.001zm0 5.625v-1.874a.7.7 0 0 0-.7-.7H11.953a.7.7 0 0 0-.7.7v1.875a.7.7 0 0 0 .7.7h11.719a.7.7 0 0 0 .703-.7v-.001zm0 5.625v-1.874a.7.7 0 0 0-.7-.7H11.953a.7.7 0 0 0-.7.7v1.875a.7.7 0 0 0 .7.7h11.719a.7.7 0 0 0 .703-.7v-.001z"
        fill="#44596C"
        fillRule="nonzero"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
)

export default List
