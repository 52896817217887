import { SVGProps } from 'react'

const History = ({ className, ...props }: SVGProps<any>) => (
  <svg
    className={`style-icon-history ${className}`}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clip-path="url(#clip0_640_4508)">
      <path
        d="M4.69471 15.3034C5.92175 16.5305 7.53633 17.2941 9.26329 17.4641C10.9902 17.6341 12.7227 17.2 14.1655 16.2357C15.6082 15.2715 16.672 13.8368 17.1754 12.1761C17.6789 10.5155 17.5909 8.73159 16.9265 7.12852C16.2621 5.52545 15.0624 4.20237 13.5317 3.38477C12.0011 2.56717 10.2343 2.30563 8.53248 2.64472C6.83062 2.98382 5.29901 3.90256 4.19866 5.24439C3.0983 6.58622 2.49729 8.2681 2.49805 10.0034V11.6667"
        stroke="#878787"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.832031 10.0001L2.4987 11.6667L4.16536 10.0001M9.16536 6.66675V10.8334H13.332"
        stroke="#878787"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_640_4508">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default History
