import { createBrowserHistory, History } from 'history'
import env from '@/env'

const history = createBrowserHistory({ basename: env.appBaseName })

type ISimpleModeSpecialPayload = {
  fillinType?: 'refrigerant'
}

export type ISimpleModelHistoryState = {
  isAddedMode: boolean
  actSimpleFillinData: Record<string, any>
  specialPayload?: ISimpleModeSpecialPayload
}

export default history as NonNullable<History>
