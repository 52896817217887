import { SVGProps } from 'react'

const Alert = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M10 1.49829L18.5606 17.6683H1.43945L10 1.49829Z"
      stroke="#878787"
      strokeWidth="1.90235"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 8.15662V11.9613"
      stroke="#878787"
      strokeWidth="1.90235"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99935 15C10.4596 15 10.8327 14.6269 10.8327 14.1667C10.8327 13.7065 10.4596 13.3334 9.99935 13.3334C9.53911 13.3334 9.16602 13.7065 9.16602 14.1667C9.16602 14.6269 9.53911 15 9.99935 15Z"
      fill="#878787"
    />
  </svg>
)

export default Alert
