import { SVGProps } from 'react'

const Evaluate = ({ className, ...props }: SVGProps<any>) => (
  <svg
    className={`style-icon-evaluate ${className}`}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.598 1.44983H2.39805C1.35305 1.44983 0.498047 2.30483 0.498047 3.34983V16.6498C0.498047 17.6948 1.35305 18.5498 2.39805 18.5498H17.598C18.643 18.5498 19.498 17.6948 19.498 16.6498V3.34983C19.498 2.30483 18.643 1.44983 17.598 1.44983ZM17.598 16.6498H2.39805V3.34983H17.598V16.6498Z"
      fill="#878787"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0372 8.499L15.6882 7.15L12.6767 10.1615L11.3372 8.8125L9.99766 10.152L12.6767 12.85L17.0372 8.499ZM3.34766 5.25H8.09766V7.15H3.34766V5.25ZM3.34766 9.05H8.09766V10.95H3.34766V9.05ZM3.34766 12.85H8.09766V14.75H3.34766V12.85Z"
      fill="#878787"
    />
  </svg>
)

export default Evaluate
