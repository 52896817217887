import { compose, forEach, pathOr, values } from 'ramda'
import { Func } from './types'
import { Role } from '@/auth/auth.types'
import { convertAuthToFuncSetting } from './converter'

export const setAuthByFunc = (auth: any, permission: Func) => {
  if (permission) {
    const child = convertAuthToFuncSetting(auth)

    return {
      ...permission,
      children: [...permission.children, child],
    }
  }
  return {
    id: auth.code,
    title: auth.name,
    children: [convertAuthToFuncSetting(auth)],
  } as Func
}

export function useRolePermission(item: Role | undefined) {
  let funcById: { [key: string]: any } = {}
  if (item) {
    compose(
      forEach((x: any) => {
        funcById[x.name] = setAuthByFunc(x, funcById[x.name])
      }),
      pathOr([], ['authDetails'])
    )(item)
  }

  return {
    data: item,
    funcList: values(funcById),
    funcById,
  }
}
