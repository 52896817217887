import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import accountZhTW from './zhTW/account.json'
import alertZhTW from './zhTW/alert.json'
import commonZhTW from './zhTW/common.json'
import deviceZhTW from './zhTW/device.json'
import formItemsZhTW from './zhTW/formItems.json'
import goodsZhTW from './zhTW/goods.json'
import menuZhTW from './zhTW/menu.json'
import reportZhTW from './zhTW/report.json'
import systemZhTW from './zhTW/system.json'
import tableColumnsZhTW from './zhTW/tableColumns.json'
import importSettingZhTW from './zhTW/importSetting.json'
import regionCoeffiZhTW from './zhTW/regionCoeffi.json'
import commonDistanceZhTW from './zhTW/commonDistance.json'
import powerinputZhTW from './zhTW/powerinput.json'
import globalEmZhTW from './zhTW/report-globalEm.json'

import accountZhCN from './zhCN/account.json'
import alertZhCN from './zhCN/alert.json'
import commonZhCN from './zhCN/common.json'
import deviceZhCN from './zhCN/device.json'
import formItemsZhCN from './zhCN/formItems.json'
import goodsZhCN from './zhCN/goods.json'
import menuZhCN from './zhCN/menu.json'
import reportZhCN from './zhCN/report.json'
import systemZhCN from './zhCN/system.json'
import tableColumnsZhCN from './zhCN/tableColumns.json'
import regionCoeffiZhCN from './zhCN/regionCoeffi.json'
import commonDistanceZhCN from './zhCN/commonDistance.json'
import importSettingZhCN from './zhCN/importSetting.json'
import powerinputZhCN from './zhCN/powerinput.json'
import globalEmZhCN from './zhCN/report-globalEm.json'

import accountEN from './en/account.json'
import alertEN from './en/alert.json'
import commonEN from './en/common.json'
import deviceEN from './en/device.json'
import formItemsEN from './en/formItems.json'
import goodsEN from './en/goods.json'
import menuEN from './en/menu.json'
import reportEN from './en/report.json'
import systemEN from './en/system.json'
import tableColumnsEN from './en/tableColumns.json'
import regionCoeffiEN from './en/regionCoeffi.json'
import commonDistanceEN from './en/commonDistance.json'
import importSettingEN from './en/importSetting.json'
import powerinputEN from './en/powerinput.json'
import globalEmEN from './en/report-globalEm.json'

import accountTh from './thTH/account.json'
import alertTh from './thTH/alert.json'
import commonTh from './thTH/common.json'
import deviceTh from './thTH/device.json'
import formItemsTh from './thTH/formItems.json'
import goodsTh from './thTH/goods.json'
import menuTh from './thTH/menu.json'
import reportTh from './thTH/report.json'
import systemTh from './thTH/system.json'
import tableColumnsTh from './thTH/tableColumns.json'
import regionCoeffiTh from './thTH/regionCoeffi.json'
import importSettingTh from './thTH/importSetting.json'
import powerinputTH from './thTH/powerinput.json'
import globalEmTh from './thTH/report-globalEm.json'

// the translations
const resources = {
  en_US: {
    menu: menuEN,
    alert: alertEN,
    account: accountEN,
    common: commonEN,
    device: deviceEN,
    formItems: formItemsEN,
    goods: goodsEN,
    report: reportEN,
    system: systemEN,
    tableColumns: tableColumnsEN,
    importSetting: importSettingEN,
    regionCoeffi: regionCoeffiEN,
    commonDistance: commonDistanceEN,
    powerinput: powerinputEN,
    globalEm: globalEmEN,
  },
  th_TH: {
    menu: menuTh,
    alert: alertTh,
    account: accountTh,
    common: commonTh,
    device: deviceTh,
    formItems: formItemsTh,
    goods: goodsTh,
    report: reportTh,
    system: systemTh,
    tableColumns: tableColumnsTh,
    importSetting: importSettingTh,
    regionCoeffi: regionCoeffiTh,
    commonDistance: commonDistanceZhTW,
    powerinput: powerinputTH,
    globalEm: globalEmTh,
  },
  zh_TW: {
    alert: alertZhTW,
    account: accountZhTW,
    common: commonZhTW,
    device: deviceZhTW,
    formItems: formItemsZhTW,
    goods: goodsZhTW,
    menu: menuZhTW,
    report: reportZhTW,
    system: systemZhTW,
    tableColumns: tableColumnsZhTW,
    importSetting: importSettingZhTW,
    regionCoeffi: regionCoeffiZhTW,
    commonDistance: commonDistanceZhTW,
    powerinput: powerinputZhTW,
    globalEm: globalEmZhTW,
  },
  zh_CN: {
    alert: alertZhCN,
    account: accountZhCN,
    common: commonZhCN,
    device: deviceZhCN,
    formItems: formItemsZhCN,
    goods: goodsZhCN,
    menu: menuZhCN,
    report: reportZhCN,
    system: systemZhCN,
    tableColumns: tableColumnsZhCN,
    importSetting: importSettingZhCN,
    regionCoeffi: regionCoeffiZhCN,
    commonDistance: commonDistanceZhCN,
    powerinput: powerinputZhCN,
    globalEm: globalEmZhCN,
  },
}

const lang = navigator.language.replace('-', '_') || 'en_US'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: [
      'alert',
      'account',
      'common',
      'device',
      'formItems',
      'goods',
      'menu',
      'report',
      'system',
      'tableColumns',
      'importSetting',
      'regionCoeffi',
      'commonDistance',
      'globalEm',
    ],
    defaultNS: 'common',
    // fallbackNS: ['common'],
    resources,
    lng: lang,
    fallbackLng: 'en_US',
    keySeparator: false, // we do not use keys in form messages.welcome
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'sub'],
    },
  })

export default i18n
