import { Pagination } from 'antd'
import styled from 'styled-components'

export default styled(Pagination)`
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: ${p => p.theme.pagination};
    border-radius: 6px;
    color: ${p => p.theme.pagination};
    background: ${p => p.theme.transparent};

    .anticon {
      vertical-align: 0em;
    }
  }

  .ant-pagination-item {
    border-color: ${p => p.theme.pagination};
    background: ${p => p.theme.transparent};
    border-radius: 4px;
  }

  .ant-pagination-item a {
    color: ${p => p.theme.pagination};
  }

  .ant-pagination-item-active,
  .ant-pagination-item-active:focus,
  .ant-pagination-item:hover {
    border-color: ${p => p.theme.pagination};
    background: ${p => p.theme.pagination};

    a {
      color: #fff;
    }
  }

  .ant-table-pagination.ant-pagination {
    margin-top: 32px;
  }

  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: ${p => p.theme.pagination};
    background: ${p => p.theme.pagination};

    svg {
      fill: #fff;
    }
  }

  .ant-pagination-simple-pager {
    color: ${p => p.theme.pagination};
  }
`
